import { CustomerRequestInfo, Unit } from "Model/CustomerRequestInfo";
import { Payment } from "Model/Payment";
import { PresaleOrder } from "Model/PresaleOrder";
import { getDateStringHumanMode, getDateMMDDYYYY } from "Util/dateUtil";
import { orderBy } from "lodash";
import { Profile } from "Model/Profile";
import { BusinessInfo } from "Model/BusinessInfo";

export const SITE_URL = "flowlylink.com";
export const DEFAULT_BUCKET_LIST = "Maybe next time";

export const REQUEST_EMAIL_TEMPLATE = (
  id: number,
  request: CustomerRequestInfo,
  emailToCustomer: boolean,
  businessInfo: BusinessInfo
) =>
  `<!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width">
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
      <title>Custom order request</title>
    <style>
  @media only screen and (max-width: 620px) {
    table[class=body] h1 {
      font-size: 28px !important;
      margin-bottom: 10px !important;
    }
  
    table[class=body] p,
  table[class=body] ul,
  table[class=body] ol,
  table[class=body] td,
  table[class=body] span,
  table[class=body] a {
      font-size: 16px !important;
    }
  
    table[class=body] .wrapper,
  table[class=body] .article {
      padding: 10px !important;
    }
  
    table[class=body] .content {
      padding: 0 !important;
    }
  
    table[class=body] .container {
      padding: 0 !important;
      width: 100% !important;
    }
  
    table[class=body] .main {
      border-left-width: 0 !important;
      border-radius: 0 !important;
      border-right-width: 0 !important;
    }
  
    table[class=body] .btn table {
      width: 100% !important;
    }
  
    table[class=body] .btn a {
      width: 100% !important;
    }
  
    table[class=body] .img-responsive {
      height: auto !important;
      max-width: 100% !important;
      width: auto !important;
    }
  }
  @media all {
    .ExternalClass {
      width: 100%;
    }
  
    .ExternalClass,
  .ExternalClass p,
  .ExternalClass span,
  .ExternalClass font,
  .ExternalClass td,
  .ExternalClass div {
      line-height: 100%;
    }
  
    .apple-link a {
      color: inherit !important;
      font-family: inherit !important;
      font-size: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
      text-decoration: none !important;
    }
  
    .btn-primary table td:hover {
      background-color: #000000 !important;
    }
  
    .btn-primary a:hover {
      background-color: #000000 !important;
      border-color: #000000 !important;
    }
  }
  </style></head>
    <body class style="background-color: #ffffff; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 14px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; background-color: #ffffff; width: 100%;" width="100%" bgcolor="#ffffff">
        <tr>
          <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
          <td class="container" style="font-family: sans-serif; font-size: 14px; vertical-align: top; display: block; max-width: 580px; padding: 10px; width: 580px; Margin: 0 auto;" width="580" valign="top">
            <div class="content" style="box-sizing: border-box; display: block; Margin: 0 auto; max-width: 580px;">
  
              <!-- START CENTERED WHITE CONTAINER -->
              <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">${
                emailToCustomer
                  ? ""
                  : `${request.count} ${
                      request.unit == Unit.Dozen ? " dozen" : " cookies"
                    } for ${request.firstName} ${request.lastName}`
              }</span>
              <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
  
                <!-- START MAIN CONTENT AREA -->
                <tr>
                  <td class="wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box;" valign="top">
                  <a href="https://flowlylink.com/${
                    businessInfo.username
                  }" target="_blank" style="color: #14192d; text-decoration: none;"><img class="profile-pic" src="${
                    businessInfo.sellerPhoto
                  }" style="border: none; -ms-interpolation-mode: bicubic; max-width: 100%; background-color: #F8F8F7; width: 56px; height: 56px; border-radius: 50%; margin-bottom: 8px; margin-top: 24px;" width="56" height="56"><h3 style="color: #14192d; font-family: sans-serif; margin: 0; font-weight: 600;">${
                    businessInfo.businessName
                  }</h3></a>
                    <hr style="border: 0; border-bottom: 1px solid #d1d1d1; Margin: 20px 0;">
  
                    <h1 style="color: #14192d; font-family: sans-serif; margin: 0; font-size: 28px; font-weight: 600; margin-bottom: 8px;">${
                      emailToCustomer
                        ? "Thanks for your custom order request!"
                        : `Request #${id.toString()}`
                    }</h1>
                  </td>
                </tr>
  
                <tr>
                  <td class="wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box;" valign="top">
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Quantity</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;"><b>${
                        request.unit == Unit.Dozen
                          ? `${request.count} dozen`
                          : `${request.count} cookies`
                      }</b></p>
                    </div>
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Date needed</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;"><b>${getDateMMDDYYYY(
                        new Date(request.customerDate)
                      )}</b></p>
                    </div>
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Fulfillment</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;"><b>${
                        request.customerFulfillment
                      }</b></p>
                    </div>
                    <hr style="border: 0; border-bottom: 1px solid #d1d1d1; Margin: 20px 0;">
  
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Customer</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${
                        request.firstName
                      } ${request.lastName}</p>
                    </div>
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Phone number</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${
                        request.phoneNumber
                      }</p>
                    </div>
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Email</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${
                        request.email
                      }</p>
                    </div>
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Preferred contact method</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${
                        request.preferredContact
                      }</p>
                    </div>
                    ${
                      request.customerFulfillment === "Pickup"
                        ? ""
                        : `<div class="info-with-label" style="margin-bottom: 8px;">
                        <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Shipping address</p>
                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${request.shippingAddress1}</p>
                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${request.shippingAddress2}</p>
                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${request.shippingCity}, ${request.shippingState} ${request.shippingZip}</p>
                      </div>`
                    }
                    <hr style="border: 0; border-bottom: 1px solid #d1d1d1; Margin: 20px 0;">
  
                    ${
                      request.bucketLists !== DEFAULT_BUCKET_LIST
                        ? `<div class="info-with-label" style="margin-bottom: 8px;">
                        <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Bucket list item</p>
                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${request.bucketLists}</p>
                      </div>`
                        : ""
                    }
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Event</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${
                        request.event
                      }</p>
                    </div>
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Theme</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${
                        request.theme === "" ? "None" : request.theme
                      }</p>
                    </div>
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Colors</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${
                        request.colors === "" ? "None" : request.colors
                      }</p>
                    </div>
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Personalization/other requirements</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${
                        request.personalization === ""
                          ? "None"
                          : request.personalization
                      }</p>
                    </div>
                    ${
                      emailToCustomer
                        ? ""
                        : `<div class="info-with-label" style="margin-bottom: 8px;">
                        <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Design inspirations</p>
                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${
                          request.designInspirations.length === 0
                            ? "None"
                            : `<table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="margin-top:8px; border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; min-width: 100%; width: 100%;" width="100%">
                            <tbody>
                              <tr>
                                <td align="left" style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: auto; width: auto;">
                                    <tbody>
                                      <tr>
                                        <td style="font-family: sans-serif; font-size: 14px; vertical-align: top; border-radius: 5px; text-align: center; background-color: #14192d;" valign="top" align="center" bgcolor="#14192d"><a href=\"${SITE_URL}/admin/requests/${id}\" target="_blank" style="border: solid 1px #14192d; border-radius: 5px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 14px; font-weight: bold; margin: 0; padding: 8px 12px; text-decoration: none; background-color: #14192d; border-color: #14192d; color: #ffffff;">Open Flowlylink</a></td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>`
                        }</p>
                        </div>`
                    }
                    ${
                      request.flavors.length > 0 &&
                      request.flavors[0].length !== 0
                        ? `<div class="info-with-label" style="margin-bottom: 8px;">
                        <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Flavor</p>
                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${request.flavors[0]}</p>
                      </div>`
                        : ""
                    }
                    ${
                      request.packagings.length > 0 &&
                      request.packagings[0].length !== 0
                        ? `<div class="info-with-label" style="margin-bottom: 8px;">
                        <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Packaging</p>
                        <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${request.packagings[0]}</p>
                      </div>`
                        : ""
                    }
                    <div class="info-with-label" style="margin-bottom: 8px;">
                      <p class="secondary-text" style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0; color: #494949;">Questions/comments</p>
                      <p style="font-family: sans-serif; font-size: 14px; font-weight: normal; margin: 0;">${
                        request.questions === "" ? "None" : request.questions
                      }</p>
                  </div>
                  </td>
                </tr>
  
              <!-- END MAIN CONTENT AREA -->
              </table>
  
              <!-- START FOOTER -->
              <div class="footer" style="clear: both; Margin-top: 10px; width: 100%;">
                <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
                  <tr>
                    <td class="content-block" style="font-family: sans-serif; vertical-align: top; padding-bottom: 10px; padding-top: 10px; color: #9a9ea6; font-size: 12px;" valign="top">
                      <hr style="border: 0; border-bottom: 1px solid #d1d1d1; Margin: 20px 0;">
                      © 2025 Flowly LLC. All rights reserved.
                    </td>
                  </tr>
                </table>
              </div>
              <!-- END FOOTER -->
  
            <!-- END CENTERED WHITE CONTAINER -->
            </div>
          </td>
          <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
        </tr>
      </table>
    </body>
  </html>
  `;
