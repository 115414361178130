import { useState } from "react";
import {
  Stack,
  FormControlLabel,
  Switch,
  Typography,
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useSession } from "Context/userAuthContext";
import { updateProfileAsync } from "Redux/Reducers/sellerProfile";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { customersAndSubscribersSelector } from "Redux/selectors";
import { toast } from "react-toastify";
import { deleteEmailFromList } from "Util/firebaseHelper";
import { deleteSubscriber } from "Redux/actionCreators";

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
    editable: false,
    sortable: true,
  },
  {
    field: "email",
    headerName: "Email",
    width: 300,
    editable: false,
    sortable: false,
  },
  {
    field: "phoneNumber",
    headerName: "Phone number",
    width: 150,
    editable: false,
    sortable: false,
  },
  {
    field: "presaleOrdersCount",
    headerName: "Presale orders",
    type: "number",
    width: 150,
    editable: false,
    sortable: true,
  },
  {
    field: "ordersCount",
    headerName: "Custom orders",
    type: "number",
    width: 150,
    editable: false,
    sortable: true,
  },
  {
    field: "subscribed",
    headerName: "On email list",
    headerAlign: "right",
    align: "right",
    type: "boolean",
    width: 150,
    editable: false,
    sortable: true,
  },
];

export default function CustomerList() {
  const { user, sellerProfile } = useSession();
  const dispatch = useAppDispatch();
  const customersAndSubscribers = useAppSelector(
    customersAndSubscribersSelector
  );

  const [openRemoveSubscriber, setRemoveSubscriber] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<string>("");
  const handleOpenRemoveSubscriber = () => {
    setRemoveSubscriber(true);
  };
  const handleCloseRemoveSubscriber = () => {
    setRemoveSubscriber(false);
  };

  const handleCellClick = (params: any) => {
    if (params.field === "email" || params.field === "phoneNumber") {
      // For "Email" and "Phone number" columns, copy the content to clipboard
      navigator.clipboard.writeText(params.value);
      toast("Copied.", {
        position: "top-center",
        autoClose: 800,
      });
    }
    if (params.field === "subscribed" && params.value) {
      setSelectedEmail(params.row.email);
      // Remove from email list
      handleOpenRemoveSubscriber();
    }
  };

  return (
    <Stack>
      <DataGrid
        rows={customersAndSubscribers}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        onCellClick={handleCellClick}
        disableColumnMenu
        disableColumnFilter
        autoHeight
        sx={{
          backgroundColor: "white",
          borderRadius: 4,
          borderWidth: 0,
          pl: 2,
          pr: 2,
          mt: 2,
          "& .MuiTablePagination-displayedRows": { margin: 0 },
        }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={!sellerProfile?.disableCustomerEmailList}
            onChange={(e) => {
              console.log("e", e.target.checked);
              if (user) {
                dispatch(
                  updateProfileAsync({
                    uid: user?.uid,
                    sellerProfile: {
                      ...sellerProfile,
                      disableCustomerEmailList: !e.target.checked,
                    },
                  })
                );
              }
            }}
          />
        }
        label="Allow customers to join email list"
        sx={{ mt: 2 }}
      />
      <Typography variant="body2" color="text.secondary">
        Once enabled, your customers can join directly from your{" "}
        <Typography variant="body2" component="span" sx={{ fontWeight: 600 }}>
          homepage, custom order form, or presale form
        </Typography>
        . You will be able to send a bulk email notification to these customers
        each time you start a new presale.
      </Typography>

      <Dialog open={openRemoveSubscriber} onClose={handleCloseRemoveSubscriber}>
        <DialogTitle>Remove from email list</DialogTitle>
        <DialogContent>
          <Typography sx={{ mb: 1, maxWidth: "400px" }}>
            This will remove{" "}
            <Typography component="span" sx={{ fontWeight: 500 }}>
              {selectedEmail}
            </Typography>{" "}
            from your email list. Are you sure you want to continue?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="secondary"
            onClick={handleCloseRemoveSubscriber}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              deleteEmailFromList(selectedEmail, sellerProfile.uid);
              dispatch(deleteSubscriber(selectedEmail));
              handleCloseRemoveSubscriber();
            }}
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
