import React, { useEffect, useState } from "react";
import { OrderInfo } from "Model/OrderInfo";
import USStates from "Components/USStates.json";
import { Unit } from "Model/CustomerRequestInfo";
import { FormikProps } from "formik";

import {
  Box,
  TextField,
  Grid,
  Stack,
  Divider,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  InputLabel,
  FormHelperText,
  Typography,
  Autocomplete,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Profile, defaultProfile } from "Model/Profile";
import { Payment, defaultPayment } from "Model/Payment";
import { InfoBox } from "Components/AllComponents";

import ForwardToInboxRoundedIcon from "@mui/icons-material/ForwardToInboxRounded";

export const NewQuoteInfo = ({
  formik,
  sellerProfile,
}: {
  formik: FormikProps<OrderInfo>;
  sellerProfile: Profile;
}) => {
  return (
    <Stack spacing={2}>
      <InfoBox sx={{ mb: 2 }}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <ForwardToInboxRoundedIcon />
          <Typography>
            Once the quote is created, a link to the quote will be emailed to
            your customer for review and acceptance. You will also get a
            sharable link for text communications.
          </Typography>
        </Stack>
      </InfoBox>

      <TextField
        fullWidth
        required
        type="number"
        id="orderTotal"
        label="Order total"
        value={formik.values.orderTotal}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        onWheel={(e: any) => e.target.blur()}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
          inputProps: { min: 0 },
        }}
        error={formik.touched.orderTotal && Boolean(formik.errors.orderTotal)}
        helperText={formik.touched.orderTotal && formik.errors.orderTotal}
      />
      <TextField
        fullWidth
        multiline
        margin="normal"
        id="notes"
        label="Note to customer (optional)"
        value={formik.values.notes}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />

      <InfoBox>
        <Stack spacing={2}>
          <Stack>
            <Typography variant="subtitle2">Date needed</Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="MM/dd/yyyy"
                value={new Date(formik.values.customerDate)}
                onChange={(e) => {
                  formik.setFieldValue("customerDate", e?.toISOString());
                }}
                slotProps={{
                  textField: {
                    required: true,
                    fullWidth: true,
                    margin: "dense",
                  },
                }}
                disablePast
              />
            </LocalizationProvider>
          </Stack>

          <Stack>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Quantity
            </Typography>
            <Stack direction="row" spacing={1} alignItems="flex-start">
              <TextField
                fullWidth
                required
                type="number"
                onWheel={(e: any) => e.target.blur()}
                id="count"
                value={formik.values.count}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.count && Boolean(formik.errors.count)}
                helperText={formik.touched.count && formik.errors.count}
              />
              <FormControl sx={{ minWidth: "110px" }}>
                <Select
                  id="unit"
                  name="unit"
                  value={formik.values.unit}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <MenuItem value={Unit.Dozen}>Dozen</MenuItem>
                  <MenuItem value={Unit.Individual}>Cookies</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>

          <Stack>
            <Typography variant="subtitle2" sx={{ mb: 1 }}>
              Contact
            </Typography>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 1 }}
              alignItems="flex-start"
              sx={{ mb: 2 }}
            >
              <TextField
                fullWidth
                required
                id="firstName"
                label="First name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
              />
              <TextField
                fullWidth
                required
                id="lastName"
                label="Last name"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
              />
            </Stack>
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 2, md: 1 }}
              alignItems="flex-start"
            >
              <TextField
                fullWidth
                id="phoneNumber"
                label="Phone number"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.phoneNumber &&
                  Boolean(formik.errors.phoneNumber)
                }
                helperText={
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                }
              />
              <TextField
                fullWidth
                required
                id="email"
                label="Email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Stack>
          </Stack>

          <Stack>
            <Typography variant="subtitle2">Fulfillment</Typography>
            <FormControl required fullWidth>
              <RadioGroup
                name="customerFulfillment"
                value={formik.values.customerFulfillment}
                onChange={formik.handleChange}
              >
                <Stack direction="row" alignItems="center">
                  <FormControlLabel
                    value="Pickup"
                    control={<Radio />}
                    label="Pickup"
                    checked={formik.values.customerFulfillment === "Pickup"}
                    defaultChecked
                  />
                  <FormControlLabel
                    value="Shipping"
                    control={<Radio />}
                    label="Shipping"
                    checked={formik.values.customerFulfillment === "Shipping"}
                    defaultChecked
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
            {/* shipping address */}
            {formik.values.customerFulfillment === "Shipping" ? (
              <div>
                <TextField
                  fullWidth
                  margin="normal"
                  required
                  id="shippingAddress1"
                  label="Address line 1"
                  value={formik.values.shippingAddress1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.shippingAddress1 &&
                    Boolean(formik.errors.shippingAddress1)
                  }
                  helperText={
                    formik.touched.shippingAddress1 &&
                    formik.errors.shippingAddress1
                  }
                />
                <TextField
                  fullWidth
                  margin="normal"
                  id="shippingAddress2"
                  label="Address line 2"
                  value={formik.values.shippingAddress2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.shippingAddress2 &&
                    Boolean(formik.errors.shippingAddress2)
                  }
                  helperText={
                    formik.touched.shippingAddress2 &&
                    formik.errors.shippingAddress2
                  }
                />
                <TextField
                  fullWidth
                  margin="normal"
                  required
                  id="shippingCity"
                  label="City"
                  value={formik.values.shippingCity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.shippingCity &&
                    Boolean(formik.errors.shippingCity)
                  }
                  helperText={
                    formik.touched.shippingCity && formik.errors.shippingCity
                  }
                />
                <Grid container columnSpacing={1}>
                  <Grid item xs={6}>
                    <FormControl
                      required
                      fullWidth
                      sx={{ mt: 2, mb: 1 }}
                      error={
                        formik.touched.shippingState &&
                        Boolean(formik.errors.shippingState)
                      }
                    >
                      <Autocomplete
                        freeSolo
                        options={USStates.map((state) => state.name)}
                        onChange={(event: any, newValue: string | null) => {
                          if (newValue) {
                            formik.setFieldValue("shippingState", newValue);
                          }
                        }}
                        inputValue={formik.values.shippingState}
                        onInputChange={(event, newInputValue) => {
                          formik.setFieldValue("shippingState", newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField required label="State" {...params} />
                        )}
                      />
                      {/* <Select
                  label="State"
                  value={formik.values.shippingState}
                  onChange={(e) => {
                    formik.setFieldValue("shippingState", e.target.value);
                  }}
                >
                  {USStates.map((state) => (
                    <MenuItem value={state.abbreviation} key={state.name}>
                      {state.name}
                    </MenuItem>
                  ))}
                </Select> */}
                      <FormHelperText>
                        {formik.touched.shippingState &&
                          formik.errors.shippingState}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      required
                      id="shippingZip"
                      label="Zip code"
                      value={formik.values.shippingZip}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.shippingZip &&
                        Boolean(formik.errors.shippingZip)
                      }
                      helperText={
                        formik.touched.shippingZip && formik.errors.shippingZip
                      }
                    />
                  </Grid>
                </Grid>
              </div>
            ) : null}
          </Stack>
        </Stack>
      </InfoBox>
    </Stack>
  );
};
