import { useState } from "react";
import moment, { Moment } from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// ui related
import {
  Button,
  Typography,
  Stack,
  IconButton,
  FormControlLabel,
  Switch,
  Box,
  Divider,
  Checkbox,
  Popover,
} from "@mui/material";
import { CalGrid } from "./Components/CalGrid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { ViewOrder } from "../Components/ViewOrder";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { ordersByCustomerDateSelector, ordersSelector } from "Redux/selectors";
import { useSession } from "Context/userAuthContext";
import {
  getDateHumanMode,
  getDateMMDDYYYY,
  getNewDateMMDDYYYY,
} from "Util/dateUtil";
import _ from "lodash";
import { updateProfileAsync } from "Redux/Reducers/sellerProfile";
import { CalMobileDay } from "./Components/CalMobileDay";
import { toast } from "react-toastify";
import BlockIcon from "@mui/icons-material/Block";
import { useParams } from "react-router-dom";
import { Availability } from "Components/BusinessView/Availability";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { CheckBox } from "@mui/icons-material";

const Calendar = () => {
  let { orderId } = useParams<"orderId">();
  const allOrders = useAppSelector(ordersSelector);
  const getOrderFromId = (orderId: string | undefined) => {
    if (orderId === undefined) {
      return undefined;
    }
    return allOrders.find((i) => i.orderId === orderId);
  };
  const orderFromUrl = getOrderFromId(orderId);

  const { user, sellerProfile } = useSession();
  const dispatch = useAppDispatch();
  const [selectedMonthMoment, setSelectedMonthMoment] = useState(
    orderFromUrl === undefined
      ? moment()
      : moment(new Date(orderFromUrl.customerDate))
  );
  const [selectedMonthString, setSelectedMonthString] = useState(
    selectedMonthMoment.format("YYYY-MM")
  );

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openDeleteRequest = Boolean(anchorEl);

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [value, setValue] = useState(
    orderFromUrl === undefined
      ? new Date()
      : new Date(orderFromUrl.customerDate)
  );
  const [blocked, setBlocked] = useState(
    sellerProfile.availability.dateBlocked.findIndex(
      (d) => d === getNewDateMMDDYYYY()
    ) !== -1
  );
  const ordersByCustomerDate = useAppSelector(ordersByCustomerDateSelector);

  const toggleBlocked = () => {
    const newValueString = getDateMMDDYYYY(value);
    setBlocked(!blocked);
    let dateBlockedCopy = _.clone(sellerProfile.availability.dateBlocked);

    const currentDate = new Date();
    const twoDaysAgo = new Date();
    twoDaysAgo.setDate(currentDate.getDate() - 2);

    // only keep last 2 days
    dateBlockedCopy = dateBlockedCopy.filter((d) => {
      const blockedDate = new Date(d);
      return blockedDate >= twoDaysAgo;
    });

    if (blocked) {
      toast(
        `${getDateHumanMode(value)} was unblocked from your availability.`,
        {
          position: "top-center",
          autoClose: 1600,
        }
      );
      dateBlockedCopy = dateBlockedCopy.filter((d) => d !== newValueString);
    } else {
      toast(`${getDateHumanMode(value)} was blocked from your availability.`, {
        position: "top-center",
        autoClose: 1600,
      });
      dateBlockedCopy.push(newValueString);
    }
    if (user) {
      dispatch(
        updateProfileAsync({
          uid: user?.uid,
          sellerProfile: {
            ...sellerProfile,
            availability: {
              ...sellerProfile.availability,
              dateBlocked: dateBlockedCopy,
            },
          },
        })
      );
    }
  };

  if (smallScreen) {
    return (
      <Stack spacing={2}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateCalendar
            views={["day"]}
            slots={{
              day: CalMobileDay,
            }}
            slotProps={{
              day: {} as any,
            }}
            value={value}
            onChange={(newValue) => {
              if (newValue) {
                setValue(newValue);
                const newValueString = getDateMMDDYYYY(newValue);
                setBlocked(
                  newValue < new Date() ||
                    sellerProfile.availability.dateBlocked.findIndex(
                      (d) => d === newValueString
                    ) !== -1
                );
              }
            }}
            sx={{
              backgroundColor: "white",
              width: "100%",
              borderRadius: 4,
              "& .MuiDayCalendar-header": { justifyContent: "space-evenly" },
              "& .MuiDayCalendar-weekContainer": {
                justifyContent: "space-evenly",
                margin: "8px 0",
              },
            }}
          />
        </LocalizationProvider>
        <Stack direction="row" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} flex={1}>
            <Typography variant="subtitle2">
              {getDateHumanMode(value)}
            </Typography>
          </Stack>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={blocked}
                onChange={toggleBlocked}
              />
            }
            labelPlacement="start"
            label="Block day"
            disabled={value < new Date()}
            sx={{
              opacity: value < new Date() ? 0 : 1,
            }}
          />
        </Stack>
        {ordersByCustomerDate.get(getDateMMDDYYYY(value))?.length ? (
          ordersByCustomerDate
            .get(getDateMMDDYYYY(value))
            ?.map((order) => (
              <ViewOrder view="kanban" orderInfo={order} key={order.orderId} />
            ))
        ) : (
          <Typography variant="body2" color="text.secondary">
            No orders
          </Typography>
        )}
      </Stack>
    );
  } else {
    return (
      <Stack>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" columnGap={1}>
            {/* display month header */}
            <Typography variant="subtitle2" flex={1} sx={{ fontWeight: 700 }}>
              {moment(selectedMonthString).format("MMMM")}{" "}
              {moment(selectedMonthString).format("YYYY")}
            </Typography>

            {/* switch month */}
            <Stack direction="row" alignItems="center">
              <IconButton
                size="small"
                color="secondary"
                onClick={() => {
                  setSelectedMonthMoment(
                    selectedMonthMoment.subtract(1, "months")
                  );
                  setSelectedMonthString(selectedMonthMoment.format("YYYY-MM"));
                }}
              >
                <ArrowBackIosNewRoundedIcon fontSize="inherit" />
              </IconButton>
              <Button
                size="small"
                sx={{ height: "fit-content" }}
                disabled={moment().isSame(selectedMonthMoment, "month")}
                onClick={() => {
                  setSelectedMonthMoment(moment());
                  setSelectedMonthString(moment().format("YYYY-MM"));
                }}
              >
                Today
              </Button>
              <IconButton
                size="small"
                color="secondary"
                onClick={() => {
                  setSelectedMonthMoment(selectedMonthMoment.add(1, "months"));
                  setSelectedMonthString(selectedMonthMoment.format("YYYY-MM"));
                }}
              >
                <ArrowForwardIosRoundedIcon fontSize="inherit" />
              </IconButton>
            </Stack>
            <Divider orientation="vertical" sx={{ height: "20px" }} />

            <Button
              size="small"
              disabled={moment().isAfter(selectedMonthMoment, "month")}
              color="secondary"
              sx={{ height: "fit-content" }}
              onClick={handleClickPopover}
            >
              Block month
            </Button>
            <Popover
              id="deletePayment"
              open={openDeleteRequest}
              anchorEl={anchorEl}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Typography sx={{ mb: 1, maxWidth: "400px" }}>
                  This will make all dates in{" "}
                  {moment(selectedMonthString).format("MMMM YYYY")} unavailable
                  for new requests. Are you sure you want to proceed?
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={handleClosePopover}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      let dateBlockedCopy = _.clone(
                        sellerProfile.availability.dateBlocked
                      );
                      const currentMonth = moment(selectedMonthString);
                      // Get the number of days in the current month
                      const daysInMonth = currentMonth.daysInMonth();
                      // Loop through each day of the month
                      for (let i = 1; i <= daysInMonth; i++) {
                        // Generate a date string for the current day
                        const date = getDateMMDDYYYY(
                          new Date(currentMonth.year(), currentMonth.month(), i)
                        );
                        // Add the date string to dateBlockedCopy
                        if (!dateBlockedCopy.includes(date)) {
                          dateBlockedCopy.push(date);
                        }
                      }
                      const currentDate = new Date();
                      const twoDaysAgo = new Date();
                      twoDaysAgo.setDate(currentDate.getDate() - 2);

                      // only keep last 2 days
                      dateBlockedCopy = dateBlockedCopy.filter((d) => {
                        const blockedDate = new Date(d);
                        return blockedDate >= twoDaysAgo;
                      });

                      if (user) {
                        dispatch(
                          updateProfileAsync({
                            uid: user?.uid,
                            sellerProfile: {
                              ...sellerProfile,
                              availability: {
                                ...sellerProfile.availability,
                                dateBlocked: dateBlockedCopy,
                              },
                            },
                          })
                        );
                      }
                      toast(
                        `All dates in ${moment(selectedMonthString).format(
                          "MMMM YYYY"
                        )} has been blocked from your availability.`,
                        {
                          position: "top-center",
                          autoClose: 1600,
                        }
                      );
                      handleClosePopover();
                    }}
                  >
                    Block month
                  </Button>
                </Stack>
              </Box>
            </Popover>
            <Divider orientation="vertical" sx={{ height: "20px" }} />
            <Availability>
              <Button color="secondary" size="small">
                Update availability
              </Button>
            </Availability>
          </Stack>

          <Stack direction="row" sx={{ pb: 1 }}>
            <Typography flex={1} variant="subtitle2" sx={{ ml: 1 }}>
              Sun
            </Typography>
            <Typography flex={1} variant="subtitle2" sx={{ ml: 1 }}>
              Mon
            </Typography>
            <Typography flex={1} variant="subtitle2" sx={{ ml: 1 }}>
              Tue
            </Typography>
            <Typography flex={1} variant="subtitle2" sx={{ ml: 1 }}>
              Wed
            </Typography>
            <Typography flex={1} variant="subtitle2" sx={{ ml: 1 }}>
              Thu
            </Typography>
            <Typography flex={1} variant="subtitle2" sx={{ ml: 1 }}>
              Fri
            </Typography>
            <Typography flex={1} variant="subtitle2" sx={{ ml: 1 }}>
              Sat
            </Typography>
          </Stack>
        </Stack>

        <CalGrid month={selectedMonthString} />
      </Stack>
    );
  }
};

export default Calendar;
