import { Theme, createTheme } from "@mui/material/styles";
import { paletteDefault, createCustomPalette } from "./palettes";

const typography = {
  fontFamily: "Roboto",
  h2: {
    fontFamily: "Inter",
    fontSize: "56px",
    fontWeight: 600,
    lineHeight: "110%",
  },
  h3: {
    fontFamily: "Frank Ruhl Libre",
    fontSize: "2.5rem",
    fontWeight: 600,
  },
  h4: { fontFamily: "Frank Ruhl Libre", fontWeight: 600 },
  h5: {
    fontFamily: "Frank Ruhl Libre",
    fontWeight: 600,
    fontSize: "1.6rem",
    letterSpacing: "0.02rem",
  },
  h6: {
    fontSize: "1.15rem",
    fontWeight: 600,
  },
  subtitle1: {
    fontFamily: "Frank Ruhl Libre",
    fontSize: "1.15rem",
    fontWeight: 400,
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: "1.04rem",
  },
  body1: {
    fontSize: "1.04rem",
  },
  body2: {
    fontSize: "0.94rem",
  },
  caption: {
    fontSize: "0.85rem",
  },
  button: {
    fontWeight: 600,
    fontSize: "0.9rem",
  },
};

export const defaultTheme = createTheme({
  palette: paletteDefault,

  typography: typography,

  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderStyle: "solid",
          borderColor: "rgba(0,0,0,0.06)",
          borderWidth: 0,
          borderBottomWidth: "thin",
          backgroundColor: "white",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            borderColor: "rgba(0,0,0,0.06)",
            backgroundColor: "white",
          },
          "& ::-webkit-scrollbar": {
            display: "none",
          },
          "&": {
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          "&.Mui-selected": {
            backgroundColor: "paletteDefault.background.default",
            // "&:hover": { backgroundColor: "paletteDefault.background.default" },
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": { color: paletteDefault.primary.dark },
        },
      },
    },
  },
});

export function createCustomTheme(userChoice: string): Theme {
  const paletteCustom = createCustomPalette(userChoice);
  return createTheme({
    palette: paletteCustom,
    typography: typography,
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            borderStyle: "solid",
            borderColor: "rgba(0,0,0,0.06)",
            borderWidth: 0,
            borderBottomWidth: "thin",
            backgroundColor: "white",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
        defaultProps: {
          disableElevation: true,
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontWeight: 500,
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              borderColor: "rgba(0,0,0,0.06)",
            },
            "& ::-webkit-scrollbar": {
              display: "none",
            },
            "&": {
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            },
          },
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            "&.Mui-selected": {
              backgroundColor: "paletteDefault.background.default",
              // "&:hover": { backgroundColor: "paletteDefault.background.default" },
            },
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            "&:hover": { color: paletteCustom.primary.dark },
          },
        },
      },
    },
  });
}
