import { useEffect, useState } from "react";
import { useSession } from "Context/userAuthContext";
import { useNavigate } from "react-router-dom";
import { joinEmailList, publishProfile } from "Util/firebaseHelper";
import _ from "lodash";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { reorder } from "Util/helpers";
import { BusinessInfo } from "Model/BusinessInfo";
import { readBusinessInfo } from "Util/firebaseHelper";
import Stories from "react-insta-stories";

// ui related
import {
  Button,
  Typography,
  Stack,
  List,
  ListItemText,
  ListItemButton,
  Divider,
  Dialog,
  Box,
  IconButton,
  TextField,
} from "@mui/material";
import { CardStyle, InfoBox } from "Components/AllComponents";
import { Profile } from "Model/Profile";
import { useAppDispatch } from "Redux/hooks";
import { editSellerProfile } from "Redux/actionCreators";
import { AvailabilityType } from "Model/Availability";
import Avatar from "boring-avatars";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "Components/Styles/carousel.css";
import { PresaleEntry } from "Pages/CustomerView/CustomerPresale/Components/PresaleEntry";

// icons
import CallMadeOutlinedIcon from "@mui/icons-material/CallMadeOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { PaymentLinks } from "Pages/BusinessView/Hub/Home/Components/PaymentLinks";
import { toast } from "react-toastify";
import { BorderRight } from "@mui/icons-material";

type HomeProps = {
  sellerProfile: Profile;
};

export const HomeContent = ({ sellerProfile }: HomeProps) => {
  const { user } = useSession();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [businessInfo, setBusinessInfo] = useState<BusinessInfo>();
  const formPublished = sellerProfile.forms && sellerProfile.forms.length > 0;
  const [email, setEmail] = useState("");
  const [openGallery, setOpenGallery] = useState(false);
  const [joinedEmailList, setJoinedEmailList] = useState(false);
  const handleOpenGallery = () => {
    setOpenGallery(true);
  };
  const handleCloseGallery = () => {
    setOpenGallery(false);
  };

  const [localLinks, setLocalLinks] = useState(sellerProfile.links);

  useEffect(() => {
    setLocalLinks(sellerProfile.links);
  }, [sellerProfile.links]);

  useEffect(() => {
    async function fetchData() {
      if (formPublished) {
        const form = await readBusinessInfo(sellerProfile.forms[0]);
        let content = form.data();
        if (content) {
          setBusinessInfo({
            id: content.id,
            businessName: content.businessName,
            formIntro: content.formIntro,
            sellerName: content.sellerName,
            sellerIntro: content.sellerIntro,
            sellerCity: content.sellerCity,
            sellerState: content.sellerState,
            fulfillmentMethod: content.fulfillmentMethod,
            shipOnlyToMyState: content.shipOnlyToMyState || false,
            minOrder: content.minOrder,
            priceChoice: content.priceChoice,
            price: content.price,
            sellerBookedUp: content.sellerBookedUp,
            sellerAvailability: content.sellerAvailability,
            orderDetails: content.orderDetails,
            sellerEmail: content.sellerEmail,
            bucketLists: content.bucketLists || [""],
            flavors: content.flavors || [""],
            packagings: content.packagings || [""],
            paymentTypes: content.paymentTypes || [""],
            agreements: content.agreements || "",
            showBucketLists: content.showBucketLists || false,
            showFlavors: content.showFlavors || false,
            showPackagings: content.showPackagings || false,
            showPaymentTypes: content.showPaymentTypes || false,
            sellerPhoto: content.sellerPhoto || "",
            aic: content.aic || false,
            username: content.username || "",
          });
        }
      }
    }
    fetchData();
  }, []);

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) {
      // var removedLinks = [...localLinks];
      // removedLinks.splice(source.index, 1);
      // setLocalLinks(removedLinks);
      // publishProfile(
      //   { ...sellerProfile, links: removedLinks },
      //   user?.uid || ""
      // );
      return;
    }
    const newLinks = reorder(localLinks, source.index, destination.index);
    setLocalLinks(newLinks);
    publishProfile({ ...sellerProfile, links: newLinks }, user?.uid || "");
    dispatch(
      editSellerProfile({
        ...sellerProfile,
        links: newLinks,
      })
    );
  };

  return (
    <Stack spacing={4}>
      {/* ------------- profile section ------------- */}
      <Stack spacing={1}>
        <Stack
          sx={{
            backgroundColor: "white",
            p: 0.25,
            alignItems: "center",
            textAlign: "center",
            width: "fit-content",
            borderRadius: "50%",
            zIndex: "1",
            boxShadow: "rgba(0, 0, 0, 0.04) 0px 4px 16px 0px",
          }}
        >
          {sellerProfile.sellerPhoto === "" ? (
            // <Avatar
            //   size={72}
            //   name={sellerProfile.sellerName ? sellerProfile.sellerName : ""}
            //   variant="beam"
            //   colors={["#F5673A", "#EA8676", "#FFB257", "#FFE2BF", "#FBED46"]}
            // />
            <svg
              viewBox="0 0 36 36"
              fill="none"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="72"
              height="72"
            >
              <mask
                id=":r126:"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="36"
                height="36"
              >
                <rect width="36" height="36" rx="72" fill="#FFFFFF"></rect>
              </mask>
              <g mask="url(#:r126:)">
                <rect width="36" height="36" fill="#c8c8b9"></rect>
                <rect
                  x="0"
                  y="0"
                  width="36"
                  height="36"
                  transform="translate(-2 6) rotate(306 18 18) scale(1)"
                  fill="#eeda8d"
                  rx="36"
                ></rect>
                <g transform="translate(-2 6) rotate(6 18 18)">
                  <path d="M13,19 a1,0.75 0 0,0 10,0" fill="#000000"></path>
                  <rect
                    x="13"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  ></rect>
                  <rect
                    x="21"
                    y="14"
                    width="1.5"
                    height="2"
                    rx="1"
                    stroke="none"
                    fill="#000000"
                  ></rect>
                </g>
              </g>
            </svg>
          ) : (
            <img
              src={sellerProfile.sellerPhoto}
              width={72}
              height={72}
              style={{
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          )}
        </Stack>

        <Typography color="text.primary" variant="h5" sx={{ pt: 0.5 }}>
          {sellerProfile.businessName}
        </Typography>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <FmdGoodOutlinedIcon fontSize="small" sx={{ opacity: 0.5 }} />
            <Typography color="text.primary" variant="subtitle2">
              {sellerProfile.sellerCity ? sellerProfile.sellerCity : "City"},{" "}
              {sellerProfile.sellerState ? sellerProfile.sellerState : "State"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <LocalMallOutlinedIcon fontSize="small" sx={{ opacity: 0.5 }} />
            <Typography color="text.primary" variant="subtitle2">
              {sellerProfile.fulfillmentMethod}
            </Typography>
          </Stack>
        </Stack>

        {sellerProfile.sellerName || sellerProfile.sellerIntro ? (
          <Stack pt={2}>
            {sellerProfile.sellerName ? (
              <Typography color="text.primary" variant="subtitle2">
                Meet {sellerProfile.sellerName}
              </Typography>
            ) : null}
            {sellerProfile.sellerIntro ? (
              <Typography
                variant="body1"
                color="text.primary"
                sx={{ whiteSpace: "pre-line" }}
              >
                {sellerProfile.sellerIntro}
              </Typography>
            ) : null}
          </Stack>
        ) : null}
      </Stack>

      <Stack spacing={2.5}>
        {/* ------------- presale ------------- */}
        {sellerProfile.livePresales.map((p) => (
          <PresaleEntry
            editing={false}
            key={p}
            presaleId={p}
            username={sellerProfile.username}
          />
        ))}

        {/* ------------- order form ------------- */}
        {formPublished ? (
          <Box
            onClick={() => {
              sellerProfile.availability.type ===
              AvailabilityType.doNotAcceptOrder
                ? console.log("not accepting order.")
                : navigate(
                    "/" +
                      sellerProfile.username +
                      "/form/" +
                      sellerProfile.forms[0]
                  );
            }}
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.04) 0px 4px 16px 0px",
              backgroundColor: "white",
              borderRadius: 4,
              p: { xs: 2, sm: 3 },
              "&:hover": {
                cursor:
                  sellerProfile.availability.type ===
                  AvailabilityType.doNotAcceptOrder
                    ? "default"
                    : "pointer",
              },
            }}
          >
            <Stack>
              <Typography
                variant="h6"
                flex={1}
                sx={{ fontFamily: "Frank Ruhl Libre", fontSize: "1.4rem" }}
              >
                Custom order
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                spacing={{ sm: 1, xs: 2 }}
                sx={{ marginTop: 0.5 }}
              >
                {sellerProfile.availability.type ===
                AvailabilityType.doNotAcceptOrder ? (
                  <Typography flex={1} variant="body1" color="text.secondary">
                    Not accepting orders
                  </Typography>
                ) : (
                  <Stack flex={1}>
                    <Typography color="text.primary" variant="subtitle2">
                      Starts at ${businessInfo?.price}/dozen
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {businessInfo?.minOrder} dozen minimum
                    </Typography>
                  </Stack>
                )}
              </Stack>
              {!sellerProfile.hideImageGallery &&
              sellerProfile.imageGallery.length > 0 ? (
                <Stack sx={{ marginTop: 2 }}>
                  <Stories
                    stories={sellerProfile.imageGallery.map((url) => ({ url }))}
                    defaultInterval={2000}
                    width="100%"
                    height="100%"
                    loop
                    storyStyles={{
                      width: "100%",
                      // aspectRatio: 1,
                      // objectFit: "cover",
                      overflow: "hidden",
                    }}
                    storyContainerStyles={{
                      borderRadius: 12,
                      overflow: "hidden",
                    }}
                    storyInnerContainerStyles={{
                      backgroundColor: "white",
                      justifyContent: "center",
                      overflow: "hidden",
                    }}
                  />
                </Stack>
              ) : null}
            </Stack>
          </Box>
        ) : null}

        {/* ------------- email list ------------- */}
        {sellerProfile.disableCustomerEmailList ||
        (!formPublished && sellerProfile.livePresales.length === 0) ? null : (
          <CardStyle
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.04) 0px 4px 16px 0px",
            }}
          >
            <Stack>
              <Typography
                variant="h6"
                flex={1}
                sx={{ fontFamily: "Frank Ruhl Libre", fontSize: "1.4rem" }}
              >
                Join email list
              </Typography>
              {!joinedEmailList ? (
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const result = await joinEmailList(
                      email,
                      sellerProfile.uid
                    );
                    if (result) {
                      setJoinedEmailList(true);
                    } else {
                      toast.error(`Failed to add ${email}`, {
                        position: "top-center",
                        autoClose: 1600,
                      });
                    }
                  }}
                >
                  <Stack flexDirection="row" alignItems="center" mt={1}>
                    <TextField
                      fullWidth
                      size="small"
                      required={true}
                      id="email"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      sx={{
                        "& .MuiInputBase-root": {
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        },
                        "& .MuiOutlinedInput-root": {
                          fieldset: { borderRightWidth: 0 },
                          "&.Mui-focused fieldset": {
                            borderWidth: "1px",
                            borderRightWidth: 0,
                          },
                        },
                      }}
                    />
                    <Button
                      variant="contained"
                      type="submit"
                      sx={{
                        width: "fit-content",
                        height: "37.93px",
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                        borderTopRightRadius: 100,
                        borderBottomRightRadius: 100,
                      }}
                    >
                      Join
                    </Button>
                  </Stack>
                </form>
              ) : (
                <Typography color="text.secondary">
                  Thank you for joining!
                </Typography>
              )}
            </Stack>
          </CardStyle>
        )}
      </Stack>

      {/* ------------- payment links ------------- */}
      {sellerProfile.payments.length > 0 ? (
        <PaymentLinks sellerProfile={sellerProfile} />
      ) : null}

      {/* ------------- custom links ------------- */}
      <List disablePadding>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-list">
            {(provided) => (
              <Stack
                ref={provided.innerRef}
                {...provided.droppableProps}
                divider={<Divider orientation="horizontal" flexItem />}
              >
                {localLinks.map((link) => (
                  <ListItemButton href={link.url} target="_blank">
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          {link.title}
                        </Typography>
                      }
                      sx={{ color: "text.primary" }}
                    />
                    <CallMadeOutlinedIcon
                      sx={{ ml: 2, color: "text.primary" }}
                    />
                  </ListItemButton>
                ))}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </DragDropContext>
      </List>
    </Stack>
  );
};
