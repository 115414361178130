import { BusinessInfo } from "Model/BusinessInfo";
import { PresaleOrder, aggregateCustomizations } from "Model/PresaleOrder";
import { Profile } from "Model/Profile";

export const PAYMENT_TYPE_LINK = "l";
export const PAYMENT_TYPE_INSTRUCTION = "i";

export const PRESALE_ORDER_EMAIL_TEMPLATE = (
  order: PresaleOrder,
  sellerProfile: Profile
) =>
  `<!doctype html>
<html>
  <head>
    <meta name="viewport" content="width=device-width">
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <title>Presale order confirmation</title>
  <style>
@media only screen and (max-width: 620px) {
  table[class=body] h1 {
    font-size: 28px !important;
    margin-bottom: 10px !important;
  }

  table[class=body] p,
table[class=body] ul,
table[class=body] ol,
table[class=body] td,
table[class=body] span,
table[class=body] a {
    font-size: 16px !important;
  }

  table[class=body] .wrapper,
table[class=body] .article {
    padding: 10px !important;
  }

  table[class=body] .content {
    padding: 0 !important;
  }

  table[class=body] .container {
    padding: 0 !important;
    width: 100% !important;
  }

  table[class=body] .main {
    border-left-width: 0 !important;
    border-radius: 0 !important;
    border-right-width: 0 !important;
  }

  table[class=body] .btn table {
    width: 100% !important;
  }

  table[class=body] .btn a {
    width: 100% !important;
  }

  table[class=body] .img-responsive {
    height: auto !important;
    max-width: 100% !important;
    width: auto !important;
  }
}
@media all {
  .ExternalClass {
    width: 100%;
  }

  .ExternalClass,
.ExternalClass p,
.ExternalClass span,
.ExternalClass font,
.ExternalClass td,
.ExternalClass div {
    line-height: 100%;
  }

  .apple-link a {
    color: inherit !important;
    font-family: inherit !important;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
    text-decoration: none !important;
  }

  .btn-primary table td:hover {
    background-color: #000000 !important;
  }

  .btn-primary a:hover {
    background-color: #000000 !important;
    border-color: #000000 !important;
  }
}
</style></head>
  <body class style="background-color: #ffffff; font-family: sans-serif; -webkit-font-smoothing: antialiased; font-size: 15px; line-height: 1.4; margin: 0; padding: 0; -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%;">
    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; background-color: #ffffff; width: 100%;" width="100%" bgcolor="#ffffff">
      <tr>
        <td style="font-family: sans-serif; font-size: 15px; vertical-align: top;" valign="top">&nbsp;</td>
        <td class="container" style="font-family: sans-serif; font-size: 15px; vertical-align: top; display: block; max-width: 580px; padding: 10px; width: 580px; Margin: 0 auto;" width="580" valign="top">          
          <div class="content" style="box-sizing: border-box; display: block; Margin: 0 auto; max-width: 580px;">
            <span class="preheader" style="color: transparent; display: none; height: 0; max-height: 0; max-width: 0; opacity: 0; overflow: hidden; mso-hide: all; visibility: hidden; width: 0;">Presale order</span>
              
            <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
              <tr>
                <td class="wrapper" style="font-family: sans-serif; font-size: 15px; vertical-align: top; box-sizing: border-box;" valign="top">

                  <a href="https://flowlylink.com/${
                    sellerProfile.username
                  }" target="_blank" style="color: #252525; text-decoration: none;">
                    <img class="profile-pic" src="${
                      sellerProfile.sellerPhoto
                    }" style="border: none; -ms-interpolation-mode: bicubic; max-width: 100%; background-color: #F8F8F7; width: 48px; height: 48px; border-radius: 50%; margin-bottom: 2px; margin-top: 24px;" width="56" height="56">
                    <h3 style="color: #252525; font-family: sans-serif; margin: 0; font-size: 15px; margin-bottom: 16px; font-weight: 600;">${
                      sellerProfile.businessName
                    }
                    </h3>
                  </a>
                </td>
              </tr>
            </table>

            
            <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
              <tr>
                <td class="wrapper" style="font-family: sans-serif; font-size: 15px; vertical-align: top; box-sizing: border-box;" valign="top">
                  <h1 style="color: #252525; font-family: sans-serif; margin: 0; font-size: 20px; font-weight: 600;">Thanks for your order!</h1>
                </td>
              </tr>
            </table>
            
            
  
            <!-- START CONTENT WITH BORDER -->
            <div class="info-box" style="border:solid 1px #eaeaea; padding: 20px; border-radius: 8px; margin-top: 16px;">
              <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
                <tr>
                  <td class="wrapper" style="font-family: sans-serif; font-size: 15px; vertical-align: top; box-sizing: border-box;" valign="top">
                    <h3 style="color: #252525; font-family: sans-serif; margin: 0; font-size: 15px; font-weight: 600; margin-bottom: 8px;">Your order will be available for pickup on ${
                      order.fulfillment.date
                    } ${order.fulfillment.range}.</h3>
                    <p class="secondary-text" style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0; color:rgb(102,102,102);">You will be contacted before the pickup date with details about the pickup address and instructions.</p>
                  </td>
                </tr>
              </table>
                  
              <!-- ORDER DETAILS -->
              <hr style="border: 0; border-bottom: 1px solid #eaeaea; Margin: 20px 0;">
              <p style="font-size:15px;line-height:1.4;margin-bottom:8px;padding:0"><b>Customer:</b> ${
                order.firstName
              } ${order.lastName}</p>
              <p style="font-size:15px;line-height:1.4;margin-bottom:15px;padding:0"><b>Order placed:</b> ${
                order.submittedDate
              }</p>

              ${Object.values(order.items)
                .map((item) =>
                  aggregateCustomizations(item)
                    .map(
                      (customization) =>
                        `
                        <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation" style="margin-top:8px">
                          <tbody>
                            <tr>
                              <td>
                                <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                                  <tbody style="width:100%">
                                    <tr style="width:100%">
                                      <td data-id="__react-email-column" style="width:64px"><img alt="item image" height="64" src=${
                                        item.img
                                      } style="display:block;outline:none;border:1px solid rgba(128,128,128,0.2);text-decoration:none;border-radius:8px" width="64" /></td>
                                      <td data-id="__react-email-column" style="padding-left:12px;vertical-align:top">
                                        <p style="font-size:15px;line-height:1.4;margin:0;font-weight:600;padding:0">${
                                          item.presaleItemName
                                        }</p>
                                        ${
                                          customization.flavor
                                            ? `
                                          <p style="font-size:15px;line-height:1.4;margin:0;color:rgb(102,102,102);padding:0">Flavor: ${customization.flavor}</p>
                                          `
                                            : ""
                                        }
                                      </td>
                                      <td align="right" data-id="__react-email-column" style="display:table-cell;padding:0px;width:100px;vertical-align:top">
                                        <p style="font-size:15px;line-height:24px;margin:0">$${
                                          customization.price
                                        } x ${customization.count}
                                          <b>&nbsp;$${
                                            customization.count *
                                            customization.price
                                          }
                                          </b>
                                        </p>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        `
                    )
                    .join("")
                )
                .join("")}
              
              <!-- ORDER TOTAL -->
              <hr style="width:100%;border:none;border-top:1px solid #eaeaea;margin:20px 0 0 0" />
              <table align="right" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                <tbody>
                  <tr>
                    <td>
                      <table align="center" width="100%" border="0" cellPadding="0" cellSpacing="0" role="presentation">
                        <tbody style="width:100%">
                          <tr style="width:100%">
                            <td align="right" data-id="__react-email-column" style="display:table-cell">
                              <p style="font-size:13px;line-height:24px;margin:0;color:rgb(102,102,102);font-weight:600;padding:0px 30px 0px 0px;text-align:right">TOTAL</p>
                            </td>
                            <td data-id="__react-email-column" style="height:48px;border-left:1px solid;border-color:rgb(238,238,238)"></td>
                            <td data-id="__react-email-column" style="display:table-cell;width:90px">
                              <p style="font-size:16px;line-height:24px;margin:0px 8px 0px 0px;font-weight:600;white-space:nowrap;text-align:right">$${
                                order.totalPrice
                              }</p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr style="width:100%;border:none;border-top:1px solid #eaeaea;margin:0" />

              <div>
                <p class="paragraph" style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0; margin-top:24px; margin-bottom: 8px;">If you haven't paid, please follow below instruction to complete your payment.</p>
                <p class="paragraph" style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0; margin-bottom: 8px;"><b>Payment method: ${
                  order.payment.method
                }</b></p>
                ${
                  order.payment.type === PAYMENT_TYPE_INSTRUCTION
                    ? `<p style="font-family: sans-serif; font-size: 15px; font-weight: normal; margin: 0;">${order.payment.instruction}</p>`
                    : `<table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary" style="margin-top:8px; border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; min-width: 100%; width: 100%;" width="100%">
                      <tbody>
                        <tr>
                          <td align="left" style="font-family: sans-serif; font-size: 15px; vertical-align: top; padding-bottom: 15px;" valign="top">
                            <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: auto; width: auto;">
                              <tbody>
                                <tr>
                                  <td style="font-family: sans-serif; font-size: 15px; vertical-align: top; border-radius: 5px; text-align: center; background-color: #252525;" valign="top" align="center" bgcolor="#252525"><a href="${order.payment.link}" target="_blank" style="border: solid 1px #252525; border-radius: 5px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 15px; font-weight: bold; margin: 0; padding: 8px 12px; text-decoration: none; background-color: #252525; border-color: #252525; color: #ffffff;">Pay now</a></td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>`
                }
              </div>
            </div>
            <!-- END CONTENT WITH BORDER -->

            <!-- START FOOTER -->
            <table role="presentation" class="main" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; min-width: 100%; width: 100%;" width="100%">
              <tr>
                <td class="wrapper" style="font-family: sans-serif; font-size: 15px; vertical-align: top; box-sizing: border-box;" valign="top">
                  <p style="font-size:13px;margin-top:24px;color:rgb(102,102,102)">Copyright © 2025 Flowly LLC. All rights reserved.</p>
                </td>
              </tr>
            </table>
            <!-- END FOOTER -->
          </div>

        </td>
        <td style="font-family: sans-serif; font-size: 15px; vertical-align: top;" valign="top">&nbsp;</td>
      </tr>
    </table>
  </body>
</html>
`;
