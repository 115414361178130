import { useEffect } from "react";
import { useSession } from "Context/userAuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { Unit } from "Model/CustomerRequestInfo";
import { getMonthFromString, getDateFromString } from "Util/dateUtil";
import {
  DEFAULT_BUCKET_LIST,
  QUOTE_STATUS_PAGE_URL,
  REQUEST_STATUS_ACCEPTED,
  REQUEST_STATUS_ORDERED,
  REQUEST_STATUS_QUOTED,
  SITE_URL,
} from "Constants";

// ui related
import {
  Typography,
  List,
  ListItemText,
  ListItemButton,
  Divider,
  Stack,
  Chip,
} from "@mui/material";

//icons
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { selectedRequestSelector } from "Redux/selectors";
import { selectRequest } from "Redux/actionCreators";

export const RequestsDrawer = () => {
  const { allRequests } = useSession();
  let { requestId } = useParams<"requestId">();

  const selectedRequest = useAppSelector(selectedRequestSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const moment = require("moment");

  useEffect(() => {
    const request = allRequests.find((r) => r.requestId === requestId);
    if (request) {
      dispatch(selectRequest(request));
    } else if (allRequests.length > 0) {
      dispatch(selectRequest(allRequests[0]));
    }
  }, [allRequests, requestId]);

  return (
    <>
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        Requests
      </Typography>
      <List component="nav" disablePadding>
        {allRequests.map((request, index) =>
          !moment(request.customerDate).isBefore(moment(), "day") &&
          request.status !== REQUEST_STATUS_ORDERED ? (
            <div key={index}>
              <ListItemButton
                selected={request.requestId === selectedRequest.requestId}
                onClick={() => {
                  dispatch(selectRequest(request));
                  navigate(`/admin/requests/${request.requestId}`);
                }}
              >
                <Stack
                  sx={{
                    mr: 2,
                    alignItems: "center",
                    width: 32,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ textTransform: "uppercase", fontWeight: 600 }}
                  >
                    {getMonthFromString(request.customerDate).toUpperCase()}
                  </Typography>
                  <Typography variant="body2">
                    {getDateFromString(request.customerDate)}
                  </Typography>
                </Stack>
                <ListItemText
                  primary={
                    <Stack direction="row" alignItems="center">
                      <Typography
                        flex={1}
                        variant="body2"
                        sx={{ fontWeight: 600 }}
                      >
                        {"Request #" + request.requestId}
                      </Typography>
                      {request.status === "" ? (
                        <Chip
                          size="small"
                          variant="filled"
                          label="New"
                          color="warning"
                          sx={{ ml: 0.5 }}
                        />
                      ) : request.status === "Quoted" ? (
                        <Chip
                          size="small"
                          variant="outlined"
                          label={request.status}
                          color="info"
                          sx={{ ml: 0.5 }}
                        />
                      ) : request.status === "Accepted" ? (
                        <Chip
                          size="small"
                          variant="filled"
                          label={request.status}
                          color="success"
                          sx={{ ml: 0.5 }}
                        />
                      ) : request.status === "Ordered" ? (
                        <Chip
                          size="small"
                          variant="outlined"
                          label={request.status}
                          color="default"
                          sx={{ ml: 0.5 }}
                        />
                      ) : null}
                    </Stack>
                  }
                  secondary={
                    request.firstName +
                    " · " +
                    request.count +
                    (request.unit == Unit.Dozen ? " dozen" : " cookies")
                  }
                />
              </ListItemButton>
            </div>
          ) : null
        )}

        <Divider sx={{ mt: 1, mb: 1 }}>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ fontWeight: 600 }}
          >
            ORDERED / EXPIRED
          </Typography>
        </Divider>

        {allRequests.map((request, index) =>
          moment(request.customerDate).isBefore(moment(), "day") ||
          request.status === REQUEST_STATUS_ORDERED ? (
            <div key={index}>
              <ListItemButton
                selected={request.requestId === selectedRequest.requestId}
                onClick={() => {
                  dispatch(selectRequest(request));
                  navigate(`/admin/requests/${request.requestId}`);
                }}
                sx={{ opacity: 0.5 }}
              >
                <Stack
                  sx={{
                    mr: 2,
                    alignItems: "center",
                    width: 32,
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{ textTransform: "uppercase", fontWeight: 600 }}
                  >
                    {getMonthFromString(request.customerDate).toUpperCase()}
                  </Typography>
                  <Typography variant="body2">
                    {getDateFromString(request.customerDate)}
                  </Typography>
                </Stack>
                <ListItemText
                  primary={
                    <Stack direction="row" alignItems="center">
                      <Typography
                        flex={1}
                        variant="body2"
                        sx={{ fontWeight: 600 }}
                      >
                        {"Request #" + request.requestId}
                      </Typography>
                      {!!request.orderId ? (
                        <Chip
                          size="small"
                          variant="outlined"
                          label="Ordered"
                          color="default"
                          sx={{ ml: 0.5 }}
                        />
                      ) : null}
                    </Stack>
                  }
                  secondary={
                    request.firstName +
                    " · " +
                    request.count +
                    (request.unit == Unit.Dozen ? " dozen" : " cookies")
                  }
                />
              </ListItemButton>
            </div>
          ) : null
        )}
      </List>
    </>
  );
};
