import React, { useEffect, useState } from "react";
import { bakeLinkSignIn, checkUsername, login } from "Util/firebaseHelper";

import {
  Button,
  Box,
  TextField,
  Stack,
  Typography,
  InputAdornment,
  Menu,
} from "@mui/material";
import { SignInBtn } from "Components/AllComponents";

type Props = {
  size: "large" | "small";
};

export const GetStarted = ({ size }: Props) => {
  const [anchorElLogin, setAnchorElLogin] = useState<null | HTMLElement>(null);
  const handleOpenLoginMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLogin(event.currentTarget);
  };
  const handleCloseLoginMenu = () => {
    setAnchorElLogin(null);
  };
  const [username, setUsername] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [usernameValidationResult, setUsernameValidationResult] = useState("");
  const [usernameValid, setUsernameValid] = useState(true);

  const validateString = (str: string) => {
    return str.match("^[A-Za-z0-9_-]+$");
  };

  const checkName = async (username: string) => {
    setUsernameValid(true);
    if (!username || username === "") {
      setUsernameValidationResult("");
    } else if (!validateString(username)) {
      setUsernameValidationResult(
        "Only letters, numbers, dashes and underscores are allowed."
      );
      setUsernameValid(false);
    } else if (username.length > 20) {
      setUsernameValidationResult(
        "Username cannot be longer than 20 characters."
      );
      setUsernameValid(false);
    } else {
      const doc = await checkUsername(username);
      if (doc.exists()) {
        setUsernameValidationResult(`${username} has already been taken.`);
        setUsernameValid(false);
      } else {
        setUsernameValidationResult("");
        setUsernameValid(true);
      }
    }
  };

  useEffect(() => {
    checkName(username);
  }, [username]);

  return (
    <div>
      <SignInBtn
        variant="contained"
        label="Start free"
        size={size}
        username=""
      />
      {/* {size === "large" ? (
        <Stack direction={{ md: "row", xs: "column" }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography variant="h6" color="text.primary">
                    flowlylink.com/
                  </Typography>
                </InputAdornment>
              ),
              style: {
                fontSize: "1.25rem",
                fontWeight: 600,
              },
            }}
            placeholder="username"
            onChange={(e) => {
              setUsername(e.target.value.toLowerCase());
            }}
            helperText={usernameValidationResult}
            error={!usernameValid}
          />
          <Box
            sx={{
              ml: { md: 1, xs: 0 },
              mt: { md: 0, xs: 1 },
              display: "flex",
              pb: usernameValid ? 0 : "20.43px",
            }}
          >
            <SignInBtn
              variant="contained"
              label="Bake my link"
              size={size}
              disabled={!usernameValid}
              username={username}
            />
          </Box>
        </Stack>
      ) : (
        <Stack direction={{ md: "row", xs: "column" }}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography color="text.primary">flowlylink.com/</Typography>
                </InputAdornment>
              ),
            }}
            placeholder="username"
            onChange={(e) => {
              setUsername(e.target.value.toLowerCase());
            }}
            helperText={usernameValidationResult}
            error={!usernameValid}
            sx={{ flexGrow: 1 }}
          />
          <Box
            sx={{
              ml: { md: 1, xs: 0 },
              mt: { md: 0, xs: 1 },
              display: "flex",
              pb: usernameValid ? 0 : "20.43px",
            }}
          >
            <SignInBtn
              variant="contained"
              label="Bake my link"
              size={size}
              disabled={!usernameValid}
              username={username}
            />
          </Box>
        </Stack>
      )} */}
    </div>
  );
};
