import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { sellerProfileSelector } from "Redux/selectors";
import dayjs, { Dayjs } from "dayjs";
import {
  getNewDateWithoutHours,
  getNewDateWithoutHoursDate,
} from "Util/dateUtil";
import {
  Button,
  Box,
  Typography,
  Stack,
  FormControl,
  FormControlLabel,
  TextField,
  Switch,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Link,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { updateProfileAsync } from "Redux/Reducers/sellerProfile";
import { useSession } from "Context/userAuthContext";
import { AvailabilityType } from "Model/Availability";
import { InfoBox } from "Components/AllComponents";

// icons
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

type Props = {
  children: React.ReactNode;
};

export const Availability = ({ children }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useSession();
  const dispatch = useAppDispatch();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { sellerProfile } = useAppSelector(sellerProfileSelector);
  const [type, setType] = useState(
    AvailabilityType[sellerProfile.availability.type]
  );

  const [leadTime, setLeadTime] = useState(sellerProfile.availability.lead);
  const [openAvailability, setOpenAvailability] = useState(false);

  const [minDateSeller, setMinDateSeller] = React.useState(
    sellerProfile.availability.start
  );

  const [maxDateSeller, setMaxDateSeller] = React.useState(
    sellerProfile.availability.end
  );
  const [requestDate, setRequestDate] = React.useState(
    getNewDateWithoutHoursDate()
  );

  const handleOpenAvailability = () => {
    if (
      sellerProfile.availability.type === AvailabilityType.acceptAfter &&
      sellerProfile.availability.start < getNewDateWithoutHours()
    ) {
      setType(AvailabilityType[AvailabilityType.acceptNow]);
    } else {
      setType(AvailabilityType[sellerProfile.availability.type]);
    }
    setLeadTime(sellerProfile.availability.lead);
    setMinDateSeller(sellerProfile.availability.start);
    setMaxDateSeller(sellerProfile.availability.end);
    setRequestDate(getNewDateWithoutHoursDate());
    setOpenAvailability(true);
  };

  const handleCloseAvailability = () => {
    setOpenAvailability(false);
  };

  const dueDateString = (date: string) => {
    var l = leadTime;
    if (leadTime === undefined) {
      l = 0;
    }
    var due = new Date(date);
    due.setDate(due.getDate() + leadTime);
    due.setHours(0, 0, 0, 0);
    var min = new Date(minDateSeller);
    min.setHours(0, 0, 0, 0);
    var max = new Date(maxDateSeller);
    max.setHours(0, 0, 0, 0);
    switch (type) {
      case AvailabilityType[AvailabilityType.acceptNow]:
        return ` , the earliest date they can choose is ${dayjs(due).format(
          "MM/DD/YYYY"
        )}.`;
      case AvailabilityType[AvailabilityType.acceptAfter]:
        if (due < min) {
          return ` , the earliest date they can choose is ${dayjs(
            minDateSeller
          ).format("MM/DD/YYYY")}.`;
        } else {
          return ` , the earliest date they can choose is ${dayjs(due).format(
            "MM/DD/YYYY"
          )}.`;
        }
      case AvailabilityType[AvailabilityType.acceptUntil]:
        if (due > max) {
          return " , there won't be available dates.";
        } else {
          return ` , they can choose a date between ${dayjs(due).format(
            "MM/DD/YYYY"
          )} and ${dayjs(maxDateSeller).format("MM/DD/YYYY")}.`;
        }
      case AvailabilityType[AvailabilityType.acceptBetween]:
        if (due > max) {
          return " , there won't be available dates.";
        } else if (due < min) {
          return ` , they can choose a date between ${dayjs(
            minDateSeller
          ).format("MM/DD/YYYY")} and ${dayjs(maxDateSeller).format(
            "MM/DD/YYYY"
          )}.`;
        } else {
          return ` , they can choose a date between ${dayjs(due).format(
            "MM/DD/YYYY"
          )} and ${dayjs(maxDateSeller).format("MM/DD/YYYY")}.`;
        }
    }
    return "";
  };

  const handleSaveAvailability = () => {
    setOpenAvailability(false);
    if (user) {
      dispatch(
        updateProfileAsync({
          uid: user?.uid,
          sellerProfile: {
            ...sellerProfile,
            availability: {
              ...sellerProfile.availability,
              type: AvailabilityType[type as keyof typeof AvailabilityType],
              lead: leadTime,
              start: minDateSeller,
              end: maxDateSeller,
            },
          },
        })
      );
    }
  };

  return (
    <div>
      <Stack direction="row" alignItems="center">
        <Box sx={{ width: "100%" }} onClick={handleOpenAvailability}>
          {children}
        </Box>
      </Stack>
      <Dialog
        open={openAvailability}
        onClose={handleCloseAvailability}
        fullScreen={fullScreen}
        fullWidth
      >
        <DialogTitle>Availability</DialogTitle>
        <DialogContent dividers>
          <FormControl fullWidth size="small">
            <FormControlLabel
              control={
                <Switch
                  checked={
                    type !== AvailabilityType[AvailabilityType.doNotAcceptOrder]
                  }
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setType(
                      event.target.checked
                        ? AvailabilityType[AvailabilityType.acceptNow]
                        : AvailabilityType[AvailabilityType.doNotAcceptOrder]
                    );
                  }}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label="Accept new orders"
              sx={{ mb: 2 }}
            />

            {type === AvailabilityType[AvailabilityType.doNotAcceptOrder] ? (
              <InfoBox>
                <Typography>
                  Your custom order form is now closed to your customers.
                </Typography>
              </InfoBox>
            ) : (
              <div>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Minimum processing time
                </Typography>
                <TextField
                  size="small"
                  id="lead"
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {leadTime > 1 ? "days" : "day"}
                      </InputAdornment>
                    ),
                    inputProps: { min: 1 },
                  }}
                  value={leadTime}
                  onChange={(e) => {
                    setLeadTime(parseInt(e.target.value));
                  }}
                  helperText="Your typical timeframe required from when a customer submits a request to when they receive the order."
                />

                <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
                  Accept orders due
                </Typography>
                <Stack
                  direction={
                    type === AvailabilityType[AvailabilityType.acceptBetween]
                      ? "column"
                      : "row"
                  }
                  alignItems={
                    type === AvailabilityType[AvailabilityType.acceptBetween]
                      ? "flex-start"
                      : "center"
                  }
                  spacing={1}
                  sx={{ mb: 1 }}
                >
                  <Select
                    value={type.toString()}
                    onChange={(event: SelectChangeEvent) => {
                      setType(event.target.value);
                      setMinDateSeller(new Date().toISOString());
                      setMaxDateSeller(new Date().toISOString());
                      setRequestDate(new Date());
                    }}
                  >
                    <MenuItem value="acceptNow">
                      {leadTime > 1
                        ? leadTime + " days from request date"
                        : leadTime + " day from request date"}
                    </MenuItem>
                    <MenuItem value="acceptAfter">after</MenuItem>
                    <MenuItem value="acceptUntil">before</MenuItem>
                    <MenuItem value="acceptBetween">between</MenuItem>
                  </Select>
                  {type ===
                  AvailabilityType[AvailabilityType.acceptNow] ? null : type ===
                    AvailabilityType[AvailabilityType.acceptAfter] ? (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={new Date(minDateSeller)}
                        onChange={(newValue) => {
                          if (newValue) {
                            setMinDateSeller(newValue.toISOString());
                          }
                        }}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                        disablePast
                      />
                    </LocalizationProvider>
                  ) : type ===
                    AvailabilityType[AvailabilityType.acceptUntil] ? (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={new Date(maxDateSeller)}
                        onChange={(newValue) => {
                          if (newValue) {
                            setMaxDateSeller(newValue.toISOString());
                          }
                        }}
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                        disablePast
                      />
                    </LocalizationProvider>
                  ) : (
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={new Date(minDateSeller)}
                          onChange={(newValue) => {
                            if (newValue) {
                              setMinDateSeller(newValue.toISOString());
                              if (new Date(maxDateSeller) < newValue) {
                                setMaxDateSeller(newValue.toISOString());
                              }
                            }
                          }}
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                          disablePast
                        />
                      </LocalizationProvider>
                      <Typography>and</Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          value={new Date(maxDateSeller)}
                          onChange={(newValue) => {
                            if (newValue) {
                              setMaxDateSeller(newValue.toISOString());
                            }
                          }}
                          slotProps={{
                            textField: {
                              size: "small",
                            },
                          }}
                          minDate={new Date(minDateSeller)}
                          disablePast
                        />
                      </LocalizationProvider>
                    </Stack>
                  )}
                </Stack>

                <Box sx={{ mt: 1 }}>
                  <InfoBox>
                    <Stack
                      flexDirection="row"
                      alignItems="center"
                      columnGap={1}
                      sx={{ mb: 1 }}
                    >
                      <HelpOutlineRoundedIcon />
                      <Typography variant="subtitle2">How it works</Typography>
                    </Stack>
                    <Typography
                      component="span"
                      color="text.primary"
                      sx={{ lineHeight: "40px" }}
                    >
                      If a customer requests on{" "}
                    </Typography>

                    <LocalizationProvider
                      // component="span"
                      dateAdapter={AdapterDateFns}
                    >
                      <DatePicker
                        value={requestDate}
                        onChange={(newValue) => {
                          if (newValue) {
                            setRequestDate(newValue);
                          }
                        }}
                        slotProps={{
                          textField: {
                            size: "small",
                            sx: { maxWidth: "150px" },
                          },
                        }}
                        disablePast
                      />
                    </LocalizationProvider>
                    <Typography
                      component="span"
                      color="text.primary"
                      sx={{ lineHeight: "40px" }}
                    >
                      {dueDateString(requestDate.toISOString())}
                    </Typography>
                  </InfoBox>

                  <Typography variant="subtitle2" sx={{ mt: 3, mb: 1 }}>
                    Block individual days
                  </Typography>
                  <Typography color="text.primary">
                    You can also block individual days on the{" "}
                    <Link
                      onClick={() => {
                        handleSaveAvailability();
                        navigate("/admin/orders");
                      }}
                    >
                      Orders Calendar
                    </Link>
                    .
                  </Typography>
                </Box>
              </div>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAvailability(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveAvailability}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
