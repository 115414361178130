import React, { useState } from "react";
import { useSession } from "Context/userAuthContext";
import _ from "lodash";

import {
  Stack,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  FormLabel,
  Alert,
  Snackbar,
  ListItemButton,
  ListItemText,
  FormHelperText,
  List,
  Box,
  Switch,
  Divider,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { FormikProps } from "formik";
import { PresaleInfo } from "Model/PresaleInfo";
import { useAppDispatch } from "Redux/hooks";
import { updatePresaleAsync } from "Redux/Reducers/presales";

import EditIcon from "@mui/icons-material/Edit";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { CardStyle, InfoBox } from "Components/AllComponents";
import { getFullDefaultAddress } from "Model/Profile";
import { InstructionSchedule } from "./InstructionSchedule";

type Props = {
  formik: FormikProps<PresaleInfo>;
};

export const PickupDate = ({ formik }: Props) => {
  const { sellerProfile } = useSession();
  const fullDefaultPickupAddress = sellerProfile.defaultPickupAddress
    ? sellerProfile.defaultPickupAddress +
      ", " +
      sellerProfile.sellerCity +
      ", " +
      sellerProfile.sellerState +
      " " +
      sellerProfile.sellerZip
    : "";
  const [index, setIndex] = useState(0);
  const [date, setDate] = useState(new Date());
  const [range, setRange] = useState("");
  const [uDA, setUDA] = useState(
    sellerProfile.defaultPickupAddress ? true : false
  );
  const [streetAddress, setStreetAddress] = useState(
    uDA ? sellerProfile.defaultPickupAddress : ""
  );
  const [cityStateZip, setCityStateZip] = useState(
    uDA
      ? sellerProfile.sellerCity +
          ", " +
          sellerProfile.sellerState +
          " " +
          sellerProfile.sellerZip
      : ""
  );
  const [addingNewDate, setAddingNewDate] = useState(false);
  const [hideStreetAddress, setHideStreetAddress] = useState(true);
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const updateFulfillment = (
    date: Date,
    range: string,
    streetAddress: string,
    cityStateZip: string,
    uDA: boolean
  ) => {
    const dateStr = date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
    const newFulfillments = [...formik.values.fulfillments];
    const address = `${streetAddress}, ${cityStateZip}`;
    if (addingNewDate) {
      newFulfillments.push({ date: dateStr, range, address, uDA });
    } else {
      newFulfillments[index] = {
        date: dateStr,
        range,
        address,
        uDA,
      };
    }
    // sort newFulfillments by date, then range, then address
    newFulfillments.sort((a, b) => {
      // compare dates
      const dateCompare = Date.parse(a.date) - Date.parse(b.date);
      if (dateCompare !== 0) {
        return dateCompare;
      }
      // if dates are equal, compare ranges
      const rangeCompare = a.range.localeCompare(b.range);
      if (rangeCompare !== 0) {
        return rangeCompare;
      }
      // if ranges are equal, compare addresses
      return a.address.localeCompare(b.address);
    });
    formik.setFieldValue("fulfillments", newFulfillments);
    dispatch(
      updatePresaleAsync({
        ...formik.values,
        fulfillments: newFulfillments,
      })
    );
  };

  return (
    <div>
      <CardStyle>
        <Typography variant="h6">Pickup dates</Typography>
        <FormHelperText error sx={{ mb: 1 }}>
          {formik.errors.fulfillments}
        </FormHelperText>
        <List disablePadding sx={{ mt: -0.5 }}>
          {formik.values.fulfillments?.map((fulfillment, index) => (
            <ListItemButton
              disableGutters
              key={index}
              onClick={() => {
                setIndex(index);
                setDate(new Date(fulfillment.date));
                const [street, ...cityStateZipParts] =
                  fulfillment.address.split(", ");
                const cityStateZip = cityStateZipParts.join(", ");
                setStreetAddress(street);
                setCityStateZip(cityStateZip);
                setRange(fulfillment.range);
                setUDA(fulfillment.uDA);
                setAddingNewDate(false);
                handleOpenModal();
              }}
            >
              <ListItemText
                primary={fulfillment.date + " " + fulfillment.range}
                secondary={fulfillment.address}
              />
              <EditIcon sx={{ ml: 2, mr: 2 }} />
            </ListItemButton>
          ))}
        </List>
        <Button
          variant="outlined"
          onClick={() => {
            setDate(new Date());
            setStreetAddress(sellerProfile.defaultPickupAddress || "");
            setCityStateZip(
              sellerProfile.sellerCity +
                ", " +
                sellerProfile.sellerState +
                " " +
                sellerProfile.sellerZip
            );
            setRange("");
            setUDA(sellerProfile.defaultPickupAddress ? true : false);
            setAddingNewDate(true);
            handleOpenModal();
          }}
          sx={{ mt: 1 }}
        >
          Add date
        </Button>

        {/* pickup instructions */}
        {/* <InstructionSchedule /> */}
      </CardStyle>

      <Dialog open={openModal} onClose={handleCloseModal} fullWidth>
        <DialogTitle>Pickup date</DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            updateFulfillment(date, range, streetAddress, cityStateZip, uDA);
            handleCloseModal();
          }}
        >
          <DialogContent dividers>
            <Stack>
              <Typography variant="subtitle2" mb={1}>
                Pickup date
              </Typography>
              <Stack
                direction={{ sm: "row", xs: "column" }}
                alignItems="center"
                columnGap={1}
                rowGap={2}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format="MM/dd/yyyy"
                    value={date}
                    onChange={(e) => {
                      if (e) {
                        setDate(e);
                      }
                    }}
                    slotProps={{
                      textField: { required: true, fullWidth: true },
                    }}
                    disablePast
                  />
                </LocalizationProvider>
                <TextField
                  fullWidth
                  id="pickupRange"
                  label="Time window"
                  value={range}
                  onChange={(e) => {
                    setRange(e.target.value);
                  }}
                />
              </Stack>

              <Stack sx={{ mt: 3 }}>
                <Typography variant="subtitle2" mb={1}>
                  Pickup location
                </Typography>

                {sellerProfile.defaultPickupAddress ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={uDA}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setUDA(e.target.checked);
                          if (e.target.checked) {
                            setStreetAddress(
                              sellerProfile.defaultPickupAddress
                            );
                            setCityStateZip(
                              sellerProfile.sellerCity +
                                ", " +
                                sellerProfile.sellerState +
                                " " +
                                sellerProfile.sellerZip
                            );
                          } else {
                            setStreetAddress("");
                            setCityStateZip("");
                          }
                        }}
                      />
                    }
                    label={fullDefaultPickupAddress}
                  />
                ) : null}
              </Stack>

              {uDA && fullDefaultPickupAddress ? (
                <Stack spacing={1}>
                  <InfoBox>
                    <Typography variant="body2" color="text.secondary">
                      The street address is hidden on the order form.
                    </Typography>
                  </InfoBox>
                </Stack>
              ) : (
                <>
                  <TextField
                    fullWidth
                    required
                    margin="dense"
                    id="streetAddress"
                    label="Street address"
                    value={streetAddress}
                    onChange={(e) => {
                      setStreetAddress(e.target.value);
                    }}
                  />
                  <TextField
                    fullWidth
                    required
                    margin="normal"
                    id="cityStateZip"
                    label="City, State, Zip"
                    value={cityStateZip}
                    onChange={(e) => {
                      setCityStateZip(e.target.value);
                    }}
                  />
                  <Alert variant="outlined" severity="info" sx={{ mt: 1 }}>
                    This street address <b>will appear</b> on the order form. To
                    hide it, use default pickup address instead, which you can
                    set in Home {">"} Business Profile.
                  </Alert>
                  {/* <FormControlLabel
                    control={
                      <Switch
                        checked={hideStreetAddress}
                        onChange={(e) => setHideStreetAddress(e.target.checked)}
                      />
                    }
                    label="Hide street address on order form"
                  /> */}
                </>
              )}

              {addingNewDate ? null : (
                <Button
                  startIcon={<DeleteRoundedIcon />}
                  onClick={() => {
                    const newFulfillments = [...formik.values.fulfillments];
                    newFulfillments.splice(index, 1);
                    formik.setFieldValue("fulfillments", newFulfillments);
                    dispatch(
                      updatePresaleAsync({
                        ...formik.values,
                        fulfillments: newFulfillments,
                      })
                    );
                    handleCloseModal();
                  }}
                  sx={{ width: "fit-content", color: "text.secondary", mt: 2 }}
                >
                  Delete date
                </Button>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              onClick={() => {
                handleCloseModal();
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
