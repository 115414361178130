import { checkUsername } from "Util/firebaseHelper";
import * as yup from "yup";
import { Availability, defaultAvailability } from "./Availability";
import { PresaleInfo } from "./PresaleInfo";
import { Payment } from "./Payment";
import { DEFAULT_COLOR_PALETTE } from "Constants";

export const profileValidationSchema = (currentUsername: string) =>
  yup.object({
    businessName: yup.string().required("This field is required"),
    // defaultPickupAddress: yup.string().required("This field is required"),
    sellerCity: yup.string().required("This field is required"),
    sellerState: yup.string().required("This field is required"),
    sellerZip: yup.string().required("This field is required"),
    username: yup
      .string()
      .required("This field is required")
      .min(1)
      .max(20, "Username cannot be longer than 20 characters.")
      .matches(
        /^[A-Za-z0-9_-]+$/,
        "Only letters, numbers, dashes and underscores are allowed."
      )
      .test(
        "checkUsername",
        "This username has already been taken.",
        function (value) {
          value = value?.toLowerCase();
          return new Promise((resolve, reject) => {
            if (value === currentUsername) {
              resolve(true);
            }
            checkUsername(value || "")
              .then((doc) => {
                // exists
                if (doc.exists()) {
                  resolve(false);
                } else {
                  resolve(true);
                }
              })
              .catch(() => {
                // note exists
                resolve(true);
              });
          });
        }
      ),
  });

export interface Link {
  id: string;
  title: string;
  url: string;
}

export enum SquareLinkedState {
  linked = "linked",
  revoked = "revoked",
  expired = "expired",
  empty = "empty",
}

export interface SquareState {
  merchantName: string;
  state: SquareLinkedState;
}

export const defaultSquareState: SquareState = {
  merchantName: "",
  state: SquareLinkedState.empty,
};

export interface Profile {
  businessName: string;
  sellerName: string;
  sellerIntro: string;
  sellerCity: string;
  sellerState: string;
  sellerZip: string;
  shipOnlyToMyState: boolean;
  fulfillmentMethod: string;
  sellerPhoto: string;
  username: string;
  links: Link[];
  forms: string[];
  presales: string[];
  livePresales: string[];
  availability: Availability;
  uid: string;
  payments: Payment[];
  defaultPickupAddress: string;
  sellerEmail: string;
  colorPalette: string;
  squareState: SquareState;
  disableCustomerEmailList: boolean;
  imageGallery: string[];
  hideImageGallery: boolean;
}

export const defaultProfile: Profile = {
  businessName: "",
  sellerName: "",
  sellerIntro: "",
  sellerCity: "",
  sellerState: "",
  sellerZip: "",
  shipOnlyToMyState: false,
  fulfillmentMethod: "Pickup only",
  sellerPhoto: "",
  username: "",
  links: [],
  forms: [],
  presales: [],
  livePresales: [],
  availability: defaultAvailability,
  uid: "",
  payments: [],
  defaultPickupAddress: "",
  sellerEmail: "",
  colorPalette: DEFAULT_COLOR_PALETTE,
  squareState: defaultSquareState,
  disableCustomerEmailList: false,
  imageGallery: [],
  hideImageGallery: false,
};

export const getFullDefaultAddress = (sellerProfile: Profile) => {
  return sellerProfile.defaultPickupAddress
    ? sellerProfile.defaultPickupAddress +
        ", " +
        sellerProfile.sellerCity +
        ", " +
        sellerProfile.sellerState +
        " " +
        sellerProfile.sellerZip
    : "";
};
