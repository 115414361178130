import CryptoJS from "crypto-js";

// Normalize phone number by removing all non-digit characters
export const normalizePhoneNumber = (phoneNumber: string): string => {
  const digits = phoneNumber.replace(/\D/g, "");
  if (digits.length < 4) {
    // Pad with zeros if less than 4 digits
    return digits.padStart(4, "0");
    // Or alternatively, throw an error:
    // throw new Error('Phone number must have at least 4 digits');
  }
  return digits.slice(-4);
};

// Use normalized phone for encryption/decryption
export const encodeRequestInfo = (
  formId: string,
  requestId: string,
  phoneNumber: string
): string => {
  const normalizedPhone = normalizePhoneNumber(phoneNumber);
  const requestInfoString = JSON.stringify({ formId, requestId });
  const encrypted = CryptoJS.AES.encrypt(
    requestInfoString,
    normalizedPhone
  ).toString();
  return encodeURIComponent(encrypted);
};

export const decodeRequestInfoInternal = (
  encoded: string,
  phoneNumber: string
): { formId: string; requestId: string } => {
  try {
    const normalizedPhone = normalizePhoneNumber(phoneNumber);
    const decrypted = CryptoJS.AES.decrypt(
      decodeURIComponent(encoded),
      normalizedPhone
    );
    const requestInfoString = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(requestInfoString) as {
      formId: string;
      requestId: string;
    };
  } catch (error) {
    return { formId: "", requestId: "" };
  }
};

// Backward compatibility function that tries both normalized and raw phone
export const decodeRequestInfo = (
  encoded: string,
  phoneNumber: string
): { formId: string; requestId: string } => {
  // Try with normalized phone first
  const normalizedResult = decodeRequestInfoInternal(
    encoded,
    normalizePhoneNumber(phoneNumber)
  );

  // If that worked, return it
  if (normalizedResult.formId && normalizedResult.requestId) {
    return normalizedResult;
  }

  // Otherwise, try with the raw phone number (for backward compatibility)
  return decodeRequestInfoInternal(encoded, phoneNumber);
};
