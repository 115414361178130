import { OrderInfo } from "Model/OrderInfo";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "Components/Styles/carousel.css";
import { Stack, Typography } from "@mui/material";
import { LabeledText } from "Components/LabeledText";
import { InfoBox } from "Components/AllComponents";

type Props = {
  orderInfo: OrderInfo;
};

export const RequestDetails = ({ orderInfo }: Props) => {
  const Carousel = require("react-responsive-carousel").Carousel;

  return (
    <Stack spacing={2}>
      {orderInfo?.flavors?.length > 0 ? (
        <Stack>
          <Typography variant="h6" color="text.primary" sx={{ mb: 0.5 }}>
            Flavor
          </Typography>
          <Typography>{orderInfo?.flavors[0]}</Typography>
        </Stack>
      ) : null}

      {orderInfo?.packagings?.length > 0 ? (
        <Stack>
          <Typography variant="h6" color="text.primary" sx={{ mb: 0.5 }}>
            Packaging
          </Typography>
          <Typography>{orderInfo?.packagings[0]}</Typography>
        </Stack>
      ) : null}

      <Stack spacing={1}>
        <Typography variant="h6" color="text.primary">
          Details
        </Typography>
        <Stack spacing={2}>
          {orderInfo?.event ? (
            <LabeledText label="Event" text={orderInfo?.event} />
          ) : null}
          {orderInfo?.theme ? (
            <LabeledText label="Theme" text={orderInfo?.theme} />
          ) : null}
          {orderInfo?.colors ? (
            <LabeledText label="Colors" text={orderInfo?.colors} />
          ) : null}
          {orderInfo?.personalization ? (
            <LabeledText
              label="Personalization/other requirements"
              text={orderInfo?.personalization}
              keepTextFormatting
            />
          ) : null}

          {orderInfo?.questions ? (
            <LabeledText
              label="Questions/comments"
              text={orderInfo?.questions}
              keepTextFormatting
            />
          ) : null}
        </Stack>
      </Stack>

      {orderInfo?.designInspirations?.length ? (
        <Stack spacing={1}>
          <Typography variant="h6" color="text.primary">
            Design inspirations
          </Typography>
          <Carousel
            showThumbs={false}
            autoPlay={false}
            infiniteLoop={true}
            dynamicHeight={true}
            showStatus={orderInfo.designInspirations.length >= 2}
          >
            {orderInfo.designInspirations.map((image, index) => (
              <img key={index} src={image} />
            ))}
          </Carousel>
        </Stack>
      ) : null}
    </Stack>
  );
};
