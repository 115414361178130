import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSession } from "Context/userAuthContext";
import { OrderInfo } from "Model/OrderInfo";
import { formatDateFromString } from "Util/dateUtil";
import { updateOrderStatus } from "Util/firebaseHelper";
import {
  useAppDispatch,
  useAppSelector,
  useCanceledOrders,
  useCompletedOrders,
} from "Redux/hooks";
import { ordersStatusSelector, selectedOrderSelector } from "Redux/selectors";
import { Unit } from "Model/CustomerRequestInfo";

// ui related
import {
  Box,
  CssBaseline,
  Chip,
  Button,
  Typography,
  Drawer,
  Stack,
} from "@mui/material";
import {
  SubNav,
  InfoBox,
  CardStyle,
  ContentWrapNarrow,
} from "Components/AllComponents";
import { CompletedOrdersDrawer } from "./CompletedOrdersDrawer";
import { Header } from "Components/Header";
import { OverviewGrid } from "Components/BusinessView/CustomOrder/OverviewGrid";
import { ContactInfo } from "Components/BusinessView/CustomOrder/ContactInfo";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "Components/Styles/carousel.css";

// icons
import { ORDER_STATUS_NOTSTARTED, ORDER_STATUS_READY } from "Constants";
import { select } from "Redux/actionCreators";
import { updateOrderStatusAsync } from "Redux/Reducers/ordersStatus";
import { updateSelectedOrderAsync } from "Redux/Reducers/selectedOrder";
import _ from "lodash";
import { LabeledText } from "Components/LabeledText";
import { RequestDetails } from "../Components/RequestDetails";
import { TrackPayment } from "Components/BusinessView/CustomOrder/TrackPayment";
import { ShipTo } from "Components/BusinessView/CustomOrder/ShipTo";

const drawerWidth = 260;
interface Props {
  window?: () => Window;
  isCancel?: boolean;
}

const CompletedOrders = (props: Props) => {
  const { orderId } = useParams<"orderId">();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useSession();
  const moment = require("moment");

  const completedOrders = useCompletedOrders();
  const getOrderFromId = (orders: OrderInfo[], orderId: string) => {
    return orders.find((i) => i.orderId === orderId);
  };
  const canceledOrders = useCanceledOrders();
  const selectedOrder = useAppSelector(selectedOrderSelector);
  const ordersStatus = useAppSelector(ordersStatusSelector);

  const moveOrder = (
    from: string,
    to: string,
    fromIndex: number,
    toIndex: number
  ) => {
    let newOrdersStatus = _.cloneDeep(ordersStatus);
    newOrdersStatus.ordersMap[from].splice(fromIndex, 1);
    newOrdersStatus.ordersMap[to].splice(toIndex, 0, selectedOrder.orderId);
    if (user) {
      dispatch(
        updateOrderStatusAsync({
          userId: user.uid,
          orderInfo: selectedOrder,
          orderStatus: to,
          ordersStatus: newOrdersStatus,
        })
      );
    }
    return newOrdersStatus;
  };

  useEffect(() => {
    if (props.isCancel) {
      if (canceledOrders !== undefined && canceledOrders.length > 0) {
        if (orderId !== undefined) {
          const order = getOrderFromId(canceledOrders, orderId);
          if (order) {
            dispatch(select(order));
          }
        } else {
          dispatch(select(canceledOrders[0]));
        }
      }
    } else {
      if (completedOrders !== undefined && completedOrders.length > 0) {
        if (orderId !== undefined) {
          const order = getOrderFromId(completedOrders, orderId);
          if (order) {
            dispatch(select(order));
          }
        } else {
          dispatch(select(completedOrders[0]));
        }
      }
    }
  }, [canceledOrders, completedOrders]);

  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  //design inspirations
  const Carousel = require("react-responsive-carousel").Carousel;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openDeleteOrder = Boolean(anchorEl);

  return (props.isCancel && canceledOrders.length === 0) ||
    (!props.isCancel && completedOrders.length === 0) ? (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CssBaseline />
      <SubNav
        returnTo="/admin/orders"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
        backBtn="Active orders"
      />
      <Box component="main" sx={{ p: { xs: 2, sm: 3 }, mt: 6, width: "100%" }}>
        <CardStyle>
          <Stack alignItems="center" spacing={2}>
            <Typography variant="subtitle2">
              You don't have any {props.isCancel ? "canceled" : "completed"}{" "}
              orders.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate("/admin/orders");
              }}
            >
              Back
            </Button>
          </Stack>
        </CardStyle>
      </Box>
    </Box>
  ) : (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* ---------- app bar ---------- */}
      <SubNav
        drawerName={
          props.isCancel ? "All canceled orders" : "All completed orders"
        }
        returnTo="/admin/orders"
        handleDrawerToggle={handleDrawerToggle}
        drawerState={mobileOpen}
        backBtn="Active orders"
      />

      {/* ---------- drawer ---------- */}
      <Box
        component="nav"
        sx={{
          width: { md: drawerWidth },
          flexShrink: { md: 0 },
        }}
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
        >
          <Box
            sx={{
              overflow: "auto",

              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <CompletedOrdersDrawer isCancel={props.isCancel} />
          </Box>
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              backgroundColor: "background.default",
            },
          }}
          open
        >
          <Box
            sx={{
              overflow: "auto",
              p: { xs: 2, sm: 3 },
              mt: 6,
            }}
          >
            <CompletedOrdersDrawer isCancel={props.isCancel} />
          </Box>
        </Drawer>
      </Box>

      {/* ---------- order content ---------- */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: { xs: 2, sm: 3 },
          mt: 6,
          width: {
            md: `calc(100% - ${(
              <CompletedOrdersDrawer isCancel={props.isCancel} />
            )}px)`,
          },
          minHeight: "100vh",
        }}
      >
        <ContentWrapNarrow>
          <Stack spacing={2}>
            <CardStyle>
              <Header
                heading={"Order #" + selectedOrder.orderId}
                chip={
                  props.isCancel ? (
                    <Chip
                      variant="outlined"
                      color="secondary"
                      size="small"
                      label="Canceled"
                    />
                  ) : (
                    <Chip
                      variant="outlined"
                      color="success"
                      size="small"
                      label="Completed"
                    />
                  )
                }
                meta={
                  selectedOrder.requestId
                    ? "Created on " +
                      formatDateFromString(selectedOrder.created)
                    : "Manually created on " +
                      formatDateFromString(selectedOrder.created)
                }
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  sx={{ height: "fit-content" }}
                  onClick={() => {
                    const newStatus = props.isCancel
                      ? ORDER_STATUS_NOTSTARTED
                      : ORDER_STATUS_READY;
                    const newOrdersStatus = moveOrder(
                      selectedOrder.status,
                      newStatus,
                      ordersStatus.ordersMap[selectedOrder.status].indexOf(
                        selectedOrder.orderId
                      ) || 0,
                      ordersStatus.ordersMap[newStatus].length || 0
                    );
                    if (user) {
                      updateOrderStatus(
                        user?.uid,
                        selectedOrder.orderId,
                        newStatus,
                        newOrdersStatus
                      );
                      dispatch(
                        updateSelectedOrderAsync({
                          userId: user?.uid,
                          orderInfo: {
                            ...selectedOrder,
                            status: newStatus,
                          },
                        })
                      );
                    }
                    if (props.isCancel) {
                      navigate(`/admin/canceled-orders`);
                    } else {
                      navigate(`/admin/completed-orders`);
                    }
                  }}
                >
                  Re-activate order
                </Button>
              </Header>

              <Box sx={{ mt: 2 }}>
                {props.isCancel ? null : (
                  <TrackPayment
                    total={selectedOrder.orderTotal}
                    paid={selectedOrder.paid}
                  />
                )}
              </Box>
              <Box sx={{ mt: 2 }}>
                <OverviewGrid
                  name={selectedOrder.firstName + " " + selectedOrder.lastName}
                  qty={`${selectedOrder.count}
            ${selectedOrder.unit == Unit.Dozen ? " dozen" : " cookies"}`}
                  date={formatDateFromString(selectedOrder.customerDate)}
                  fulfillment={selectedOrder.customerFulfillment}
                />
              </Box>
              {selectedOrder.notes ? (
                <InfoBox sx={{ mt: 2 }}>
                  <LabeledText label="Notes" text={selectedOrder.notes} />
                </InfoBox>
              ) : null}
            </CardStyle>

            <CardStyle>
              <ContactInfo
                phone={selectedOrder.phoneNumber}
                email={selectedOrder.email}
                preferredContact={selectedOrder.preferredContact}
              />
            </CardStyle>

            {selectedOrder.customerFulfillment === "Pickup" ? null : (
              <CardStyle>
                <ShipTo
                  shippingAddress1={selectedOrder.shippingAddress1}
                  shippingAddress2={selectedOrder.shippingAddress2}
                  shippingCity={selectedOrder.shippingCity}
                  shippingState={selectedOrder.shippingState}
                  shippingZip={selectedOrder.shippingZip}
                />
              </CardStyle>
            )}

            {selectedOrder.requestId ? (
              <CardStyle>
                <RequestDetails orderInfo={selectedOrder} />
              </CardStyle>
            ) : null}
          </Stack>
        </ContentWrapNarrow>
      </Box>
    </Box>
  );
};

export default CompletedOrders;
