import { useNavigate } from "react-router-dom";

import { Button, Typography, Box, Stack, Chip } from "@mui/material";
import { GetStarted, SignInBtn, Footer } from "Components/AllComponents";
import Logo from "Assets/logo-full.svg";
import LogoG from "Assets/logo-graph.svg";
import { useSession } from "Context/userAuthContext";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import SquareIconSrc from "../Assets/square.svg";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import LandingPhoto from "Assets/landing_photo.png";
import LandingInquiry from "Assets/landing_inquiry.png";
import LandingCalendar from "Assets/landing_calendar.png";
import LandingPresale from "Assets/landing_presale.png";
import LandingUser from "Assets/landing_user.png";

const Landing = () => {
  const { user } = useSession();
  const navigate = useNavigate();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const midScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Stack
      alignItems="center"
      sx={{
        pt: { xs: 2, sm: 3 },
        pb: 4,
        backgroundColor: "#FAFBF9",
        minHeight: "100vh",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        sx={{ width: "100%", pl: { xs: 2, sm: 6 }, pr: { xs: 2, sm: 6 } }}
      >
        <Stack flex={1} alignItems="center" direction="row" spacing={1}>
          <a href="/" style={{ width: 22, height: 22 }}>
            <img src={LogoG} alt="Logo" style={{ width: 22, height: 22 }} />
          </a>
        </Stack>
        <Button
          variant="text"
          onClick={async () => {
            navigate("/discover");
          }}
          sx={{ height: "fit-content" }}
        >
          Inspirations
        </Button>
        {user ? (
          <Button
            variant="contained"
            onClick={async () => {
              navigate("/admin/home");
            }}
            sx={{ height: "fit-content" }}
          >
            Admin
          </Button>
        ) : (
          <Box>
            <SignInBtn variant="outlined" label="Sign in" username="" />
          </Box>
        )}
      </Stack>

      <Stack
        direction={smallScreen ? "column" : "row"}
        spacing={4}
        alignItems="center"
        maxWidth="1100px"
        mt={smallScreen ? 6 : 12}
      >
        <Stack
          flex={1}
          sx={{
            textAlign: smallScreen ? "center" : "left",
            pl: 2,
            pr: 2,
          }}
        >
          <Box>
            <img
              src={Logo}
              alt="Logo"
              style={{
                width: 110,
                height: 42,
                marginBottom: smallScreen ? 20 : 40,
              }}
            />
          </Box>
          <Typography
            variant={smallScreen ? "h3" : "h2"}
            sx={{
              fontFamily: "inter",
            }}
          >
            Easy to use business software for cookiers
          </Typography>
          <Typography
            color="text.secondary"
            sx={{
              mt: 4,
              fontWeight: 400,
              lineHeight: "140%",
              fontSize: "1.25rem",
            }}
          >
            Flowlylink is designed to simplify order taking, keep orders
            organized, and delight customers.{" "}
            <Typography
              component="span"
              sx={{
                fontWeight: 500,
                lineHeight: "140%",
                fontSize: "1.25rem",
                color: "text.primary",
              }}
            >
              Start selling today.
            </Typography>
          </Typography>
          {user ? null : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent={smallScreen ? "center" : "start"}
              spacing={2}
              sx={{ mt: 5 }}
            >
              <GetStarted size="large" />
              <Button
                variant="text"
                size="large"
                onClick={() =>
                  window.open("https://www.flowlylink.com/demo", "_blank")
                }
              >
                <Typography variant="h6">View example</Typography>
              </Button>
            </Stack>
          )}
        </Stack>
        <Box flex={1} textAlign={smallScreen ? "center" : "end"}>
          <Box
            component="img"
            src={LandingPhoto}
            width={smallScreen ? "60%" : "80%"}
            alt="img"
            sx={{
              borderRadius: "12px",
              borderTopRightRadius: "64px",
              borderBottomLeftRadius: "64px",
            }}
          />
        </Box>
      </Stack>

      <Stack
        mt={smallScreen ? 8 : 15}
        alignItems="center"
        sx={{
          pl: 2,
          pr: 2,
          maxWidth: "1100px",
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontFamily: "inter",
            fontSize: smallScreen ? "32px" : "42px",
            fontWeight: 500,
            lineHeight: "130%",
          }}
        >
          Your all-in-one solution
        </Typography>
        <Stack
          direction={smallScreen ? "column" : "row"}
          spacing={smallScreen ? 3 : 10}
          mt={smallScreen ? 3 : 6}
        >
          {[
            {
              title: "Order inquiry",
              description:
                "Collect essential details for precise quotes with a customizable form.",
              icon: LiveHelpOutlinedIcon,
            },
            {
              title: "Order calendar",
              description:
                "Manage availability and track orders from request to filfullment.",
              icon: EventAvailableOutlinedIcon,
            },
            {
              title: "Presale",
              description:
                "Get paid upfront with Square integration and keep orders organized.",
              icon: CreditCardOutlinedIcon,
            },
          ].map((item, index) => (
            <Stack
              key={index}
              flex={1}
              spacing={2.5}
              direction={smallScreen ? "row" : "column"}
              alignItems={smallScreen ? "flex-start" : "flex-start"}
              sx={{ textAlign: "left" }}
            >
              <item.icon sx={{ fontSize: 32, color: "primary.main" }} />
              <Stack spacing={1}>
                <Typography
                  sx={{
                    fontFamily: "inter",
                    fontSize: "20px",
                    fontWeight: 600,
                    lineHeight: "120%",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  color="text.secondary"
                  sx={{
                    fontFamily: "inter",
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: "150%",
                  }}
                >
                  {item.description}
                </Typography>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>

      <Stack
        spacing={smallScreen ? 4 : 8}
        mt={smallScreen ? 4 : 8}
        pl={2}
        pr={2}
      >
        <Stack
          direction={smallScreen ? "column" : "row"}
          spacing={smallScreen ? 3 : 6}
          alignItems={smallScreen ? "flex-start" : "center"}
          maxWidth="1300px"
          sx={{
            backgroundColor: "#e2eada",
            pt: smallScreen ? 2 : 6,
            pr: smallScreen ? 2 : 6,
            pl: smallScreen ? 2 : 0,
            pb: smallScreen ? 3 : 0,
            borderRadius: "24px",
            overflow: "hidden",
          }}
        >
          <Box flex={3} textAlign={smallScreen ? "center" : "start"}>
            <Box
              component="img"
              src={LandingInquiry}
              width="100%"
              alt="img"
              sx={{
                display: "block",
                borderRadius: smallScreen ? "12px" : "0px",
                borderTopRightRadius: "12px",
              }}
            />
          </Box>
          <Stack alignItems="start" flex={2} spacing={3}>
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "inter",
                fontSize: smallScreen ? "32px" : "42px",
                fontWeight: 500,
                lineHeight: "130%",
              }}
            >
              Receive requests and send quotes
            </Typography>
            <Stack spacing={1}>
              <Typography
                color="text.secondary"
                sx={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                }}
              >
                Set up an order form in seconds
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                }}
              >
                Send quote for approval
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                }}
              >
                Convert quote to order
              </Typography>
            </Stack>
            {user ? null : (
              <Stack sx={{ alignItems: "center" }}>
                <GetStarted size="large" />
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack
          direction={smallScreen ? "column" : "row"}
          spacing={smallScreen ? 3 : 6}
          alignItems={smallScreen ? "flex-start" : "center"}
          maxWidth="1300px"
          sx={{
            backgroundColor: "#e2eada",
            pt: smallScreen ? 2 : 6,
            pl: smallScreen ? 2 : 6,
            pr: smallScreen ? 2 : 0,
            pb: smallScreen ? 2 : 0,
            borderRadius: "24px",
            overflow: "hidden",
          }}
        >
          <Stack alignItems="start" flex={2} spacing={3}>
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "inter",
                fontSize: smallScreen ? "32px" : "42px",
                fontWeight: 500,
                lineHeight: "130%",
              }}
            >
              Never forget an order
            </Typography>
            <Stack spacing={1}>
              <Typography
                color="text.secondary"
                sx={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                }}
              >
                Keep orders organized
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                }}
              >
                Track order status
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                }}
              >
                Block dates
              </Typography>
            </Stack>
            {user ? null : (
              <Stack sx={{ alignItems: "center" }}>
                <GetStarted size="large" />
              </Stack>
            )}
          </Stack>
          <Box flex={3} textAlign={smallScreen ? "center" : "start"}>
            <Box
              component="img"
              src={LandingCalendar}
              width="100%"
              alt="img"
              sx={{
                display: "block",
                borderRadius: smallScreen ? "12px" : "0px",
                borderTopLeftRadius: "12px",
              }}
            />
          </Box>
        </Stack>
        <Stack
          direction={smallScreen ? "column" : "row"}
          spacing={smallScreen ? 3 : 6}
          alignItems={smallScreen ? "flex-start" : "center"}
          maxWidth="1300px"
          sx={{
            backgroundColor: "#e2eada",
            pt: smallScreen ? 2 : 6,
            pr: smallScreen ? 2 : 6,
            pl: smallScreen ? 2 : 0,
            pb: smallScreen ? 3 : 0,
            borderRadius: "24px",
            overflow: "hidden",
          }}
        >
          <Box flex={3} textAlign={smallScreen ? "center" : "start"}>
            <Box
              component="img"
              src={LandingPresale}
              width="100%"
              alt="img"
              sx={{
                display: "block",
                borderRadius: smallScreen ? "12px" : "0px",
                borderTopRightRadius: "12px",
              }}
            />
          </Box>
          <Stack alignItems="start" flex={2} spacing={3}>
            <Typography
              sx={{
                textAlign: "left",
                fontFamily: "inter",
                fontSize: smallScreen ? "32px" : "42px",
                fontWeight: 500,
                lineHeight: "130%",
              }}
            >
              Run presales with ease
            </Typography>
            <Stack spacing={1}>
              <Typography
                color="text.secondary"
                sx={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                }}
              >
                Sell personalizable products
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                }}
              >
                Customize designs and flavors
              </Typography>
              <Typography
                color="text.secondary"
                sx={{
                  fontFamily: "inter",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "150%",
                }}
              >
                Take payment via Square
              </Typography>
            </Stack>
            {user ? null : (
              <Stack sx={{ alignItems: "center" }}>
                <GetStarted size="large" />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Stack
        direction={smallScreen ? "column-reverse" : "row"}
        spacing={4}
        alignItems="center"
        maxWidth="1000px"
        mt={8}
        pl={2}
        pr={2}
      >
        <Stack
          flex={3}
          sx={{
            textAlign: smallScreen ? "center" : "left",
            pl: 2,
            pr: 2,
          }}
        >
          <Typography
            sx={{
              fontFamily: "inter",
              fontSize: smallScreen ? "16px" : "20px",
              fontWeight: 500,
              lineHeight: "130%",
            }}
          >
            Setting up was a breeze! Flowlylink has saved me so much time,
            allowing me to focus more on my cookies and less on the admin work.
          </Typography>
          <Typography
            mt={3}
            sx={{
              fontFamily: "inter",
              fontSize: smallScreen ? "16px" : "20px",
              fontWeight: 500,
              lineHeight: "130%",
            }}
          >
            Rosie, cookie artist
          </Typography>
          {user ? null : (
            <Stack
              sx={{ mt: 4, alignItems: smallScreen ? "center" : "flex-start" }}
            >
              <GetStarted size="large" />
            </Stack>
          )}
        </Stack>
        <Box flex={2} textAlign={smallScreen ? "center" : "end"}>
          <Box
            component="img"
            src={LandingUser}
            width={smallScreen ? "70%" : "100%"}
            alt="img"
            sx={{
              borderRadius: "12px",
            }}
          />
        </Box>
      </Stack>

      <Footer />
    </Stack>
  );
};

export default Landing;
